import React, { lazy, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import {  EMPLOYEE_DETAILS_MODULE_ACCESS, ONBOARDING_MODULE_ACCESS, USER_EMP_MASTER_ACCESS_VIEW } from "../permission/access";

const EmpDetails = lazy(() =>
    import("../components/EmpDetails/EmpDetails")
);
const CandidateApproveReject = lazy(() =>
    import("../components/Onboarding/CandidateApproveReject")
);
const DocumentsApproveList = lazy(() =>
    import("../components/Onboarding/DocumentsApproveList")
);
const OnboardingInitiation = lazy(() =>
    import("../components/Onboarding/OnboardingInitiation")
);
const NewJoineesListAdmin = lazy(() =>
    import("../components/NewJoinees/NewJoineesListAdmin")
);
const NewJoineesForm = lazy(() =>
    import("../components/NewJoinees/NewJoineesForm")
);


export const UserSuperModuleRoutes = ({ match }) => {
    let { authUser } = useSelector(({ auth }) => auth);
    let reportingmanager = localStorage.getItem("is_reporting_manager");
    const isOnboardingAccess = ONBOARDING_MODULE_ACCESS?.includes(authUser?._id);
    const isEmpMasterAccess = USER_EMP_MASTER_ACCESS_VIEW.includes(authUser._id)
    return (
        <div className="gx-main-content-wrapper">
            <Switch>
                {EMPLOYEE_DETAILS_MODULE_ACCESS.includes(authUser?._id) && <Route
                    exact
                    path={`${match.url}viewEmployeeDetails`}
                    component={EmpDetails}
                />}
                {isOnboardingAccess && (
                    <>
                        <Route
                            exact
                            path="/new-joinees-form"
                            component={NewJoineesForm}
                        />
                        <Route
                            exact
                            path="/new-joinees-list"
                            component={NewJoineesListAdmin}
                        />
                        <Route
                            exact
                            path="/onboarding-initiation"
                            component={OnboardingInitiation}
                        />
                        {/* <Route
                            exact
                            path="/doc-approve-list"
                            component={DocumentsApproveList}
                        /> */}
                        <Route
                            exact
                            path="/doc-approve-reject/:id"
                            component={CandidateApproveReject}
                        />
                    </>
                )}
            </Switch>

        </div>
    )
}
