import React, { useEffect } from "react";
import { Button, Input, message, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Service from "../service";
import axios from "axios";
// import ElsnerElevate from "assets/images/ElsnerElevate.svg";
import Logowhite from "assets/images/Logowhite.svg";
// import icons8lock from "assets/images/icons8-lock.svg";
import {
  showAuthLoader,
  hideAuthLoader,
  userSignInSuccess,
} from "../appRedux/actions/Auth";
import IntlMessages from "../util/IntlMessages";
import moment from "moment";
import { setIsPasswordChange } from "../appRedux/actions/Common";

const SignIn = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Accessing a specific query parameter, e.g., 'redirect_uri'
  const redirect_uri = queryParams.get("redirect_uri");
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const Logo = localStorage.getItem("LogoURL");
const isPassword=useSelector((Common)=>Common)
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
console.log(isPassword.common.isPassword,'isPasswordisPassword');
// const dis
  const SSORedirect = async (response) => {
    if (response?.data && response?.data?.data) {
      window.open(
        `${redirect_uri}?token=${response.data.authToken.accessToken}`,
        "_self"
      );
    }
  };
  const onFinish = async (values) => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.login,
        body: values,
      });
      dispatch(hideAuthLoader());

      if (response?.data && response?.data?.data) {
        if (redirect_uri) {
          SSORedirect(response);
        }
        const userData = response.data.data;
        console.log(userData.isPasswordChanged,'userData');
        
dispatch(setIsPasswordChange(userData.isPasswordChanged))
        
        localStorage.setItem("user_data", JSON.stringify(userData));
        localStorage.setItem(
          "accessToken",
          response.data.authToken.accessToken
        );
        localStorage.setItem(
          "ssoToken",
          response.data.tokenForSSO.refreshToken
        );
        localStorage.setItem(
          "refreshToken",
          response.data.authToken.refreshToken
        );
        localStorage.setItem(
          "Last Login Time",
          moment().format("MMMM Do YYYY, h:mm:ss a")
        );
        localStorage.setItem(
          "resourceAccess",
          JSON.stringify(response.data.permissions)
        );
        history.push("/dashboard");
       
        dispatch(userSignInSuccess(userData));
        // if(userData?.emp_code){
        //   history.push('/employee-master')
        // }
        // else{
        //   history.push('/dashboard')
        // }
        // message.success(response.data.message);
      } else {
        const msg = response.data.message;
        const index = Service.add_message(msg);
        if (index === -1) { 
          message.error(msg).then(() => {
            Service.remove_message(msg);
          });
        }
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  return (
    <div className="gx-app-login-wrap account-login">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo account_logo">
              {Logo ? (
                <img alt="example" src={Logo} />
              ) : (
                <img alt="example" src={Logowhite} />
              )}
            </div>
            <div className="gx-app-login-left-content">
              <h6>Welcome to,</h6>
              <h2>Elsner HRMS Portal</h2>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="form-center">
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signIn" />
                </h1>
              </div>
              <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
              >
                <div className="form-label">
                  <span>
                    <label>Login Id</label>
                  </span>
                </div>
                <div className="form-content">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Login Id!",
                      },
                      {
                        type: "email",
                        message: "Please enter valid Login Id",
                      },
                    ]}
                    name="email"
                  >
                    <Input type="email" placeholder="Login Id" />
                  </Form.Item>
                  <span className="login-icon">
                    <i className="fas fa-envelope"></i>
                  </span>
                </div>
                <div className="form-label">
                  <span>
                    <label>Password</label>
                  </span>
                  {/* <span>Forgot Password?</span> */}
                </div>
                <div className="form-content">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                  <span className="login-icon lock">
                    <i className="fas fa-lock"></i>
                  </span>
                </div>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </Form.Item>
                <Form.Item>
                  <center>
                    Forgot your Login details?
                    <Link to="/forgot-password">
                      &nbsp;Get help logging in.
                    </Link>
                  </center>
                </Form.Item>
              </Form>
            </div>
          </div>

          {loader ? (
            <div className="gx-loader-view">{/* <CircularProgress/> */}</div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
