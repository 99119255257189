

exports.jobAccess = [
    '64fffa358a2e0d007769e9c8', 
    '63b6ca8b70f2b5b3002cb466', 
    '63b6ca8b70f2b5b3002cb45e', 
    '63b6ca8a70f2b5b3002cb456', 
    '63b6ca8970f2b5b3002cb44e', 
    '63b6ca8870f2b5b3002cb44a', 
    '63b6ca8870f2b5b3002cb446', 
    '63b6ca8770f2b5b3002cb43e', 
    '64f17dd897dcb20096115402', 
    "667122ea8328a7003f927970", //gunjan
    '63b6caa070f2b5b3002cb70e', 
    '63b6ca9270f2b5b3002cb552',
    "63b6ca8670f2b5b3002cb433",//harshal
    "63b6ca8770f2b5b3002cb43e", //Chirag
    "64e2e494e2603d00c6e97efb", //Mansi
    "64a512a7fed85d00290e3a75", //Disha
    "63b6caa770f2b5b3002cb7ee", //mini
    "63b6caa570f2b5b3002cb7ae", //Preeti
    "63b6caa070f2b5b3002cb70e", //Prity
    "64f17dd897dcb20096115402", //pallav
    "667122ea8328a7003f927970", //gunjan
    "63b6ca9270f2b5b3002cb552", //Team HR 
    "63b6caa170f2b5b3002cb722", //het
    "6597c1befc7515034d3cecdd", //Nikita
    "6597b257fc7515034d3ca53c", //Chaitanya
    "6583c52e62f944003100aa3b", //Harsiddhi
    "65c07852cda0ce003fea1af5"  //Sreenath
];

exports.onboardingAcess = [
    '63b6caa570f2b5b3002cb7ae', //preeti
    '63b6caa770f2b5b3002cb7ee', //mini
    '64f17dd897dcb20096115402', //pallav
    "667122ea8328a7003f927970", //gunjan
    '63b6caa070f2b5b3002cb70e', //Prity
    "6583c52e62f944003100aa3b", //Harsiddhi
    "6597c1befc7515034d3cecdd", //Nikita 
    "6597b257fc7515034d3ca53c"  //Chaitanya
]

exports.notificationAccessBlock = ["6580097932cb9d14225b12a8"] //Block for intern

exports.empMasterAccess = ["65681f326973ba0031bf3b2b"]

exports.ADMIN_ROLE_ID = "60e68a055f40862222c64ec9"
exports.DIRECTOR_ROLE_ID = "64217927e37dabdb7ba3aeb3"
exports.CEO_ROLE_ID = "642269126c284b24a250500a"