import React from "react";
import { useSelector } from "react-redux";
import {
  ADMIN_OR_HR_ROLE_ID,
  CEO_ROLE_ID,
  DIRECTOR_ROLE_ID,
  HR_TEAM_ROLE_ID,
  INTERN_ROLE_ID,
  NETWORK_TEAM_ROLE_ID,
  USER_ROLE_ID,
} from "../permission/access";
import DirectorRoutes from "./DirectorRoutes";
import CEORoutes from "./CEORoutes";
import AdminRoutes from "./AdminRoutes";
import UserOrTLRoutes from "./UserOrTLRoutes";
import InternRoutes from "./InternRoutes";
import NTeamRoutes from "./NTeamRoutes";

const App = ({ match }) => {
  let { authUser } = useSelector(({ auth }) => auth);
  let isassistantmanager = localStorage.getItem(
    "is_assistant_reporting_manager"
  );
  let ismanager = localStorage.getItem("is_reporting_manager");

  return (
    <>
      {authUser?.role_id === INTERN_ROLE_ID ? (
        <>
          <InternRoutes match={match} />
        </>
      ) : // Admin Routes
      authUser?.role_id?._id === ADMIN_OR_HR_ROLE_ID ||
        authUser?.role_id === ADMIN_OR_HR_ROLE_ID ? (
        <>
          <AdminRoutes match={match} />
        </>
      ) : // Director Routes
      authUser?.role_id === DIRECTOR_ROLE_ID ? (
        <>
          <DirectorRoutes match={match} />
        </>
      ) : // Ceo Routes
      authUser?.role_id === CEO_ROLE_ID ? (
        <>
          <CEORoutes match={match} />
        </>
      ) : authUser?.role_id === NETWORK_TEAM_ROLE_ID ? (
        <NTeamRoutes match={match} />
      ) : authUser?.role_id?._id === USER_ROLE_ID ||
        // User-TL Routes
        authUser?.role_id === USER_ROLE_ID ||
        authUser?.role_id === HR_TEAM_ROLE_ID || // HR Team role ID
        isassistantmanager == "true" ? (
        <>
          <UserOrTLRoutes match={match} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
