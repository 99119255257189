import React, { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";




const OrganizationView = lazy(() =>
    import("../components/OrganizationView/OrganizationView")
);

const OrganizationPolicy = lazy(() =>
    import("../components/OrganizationPolicy/OrganizationPolicy")
);

const UserDashboard = lazy(() =>
    import("../components/UserDashboard/UserDashboard")
);

const MyInOut = lazy(() => import("../components/MyInOut/MyInOut"));

//Admin-Reports

const Userreports = lazy(() => import("../components/UserReports/UserReport"));

const UserAttendenceReport = lazy(() =>
    import("../components/UserReports/AttendenceReport/UserAttendenceRegister")
);
const UserInoutReport = lazy(() =>
    import("../components/UserReports/AttendenceReport/UserInOutRegister")
);

// employee information management
const EmpInfoManagement = lazy(() =>
    import("../components/EmpInfoManagement/EmpInfoManagement")
);

const internRoutes = [
    {
        path: "dashboard",
        component: UserDashboard
    },
    {
        path: "my-in-out",
        component: MyInOut
    },
    {
        path: "user-reports",
        component: Userreports
    },
    {
        path: "user-reports/attendence-reports/attendence-register",
        component: UserAttendenceReport
    },
    {
        path: "user-reports/attendence-reports/inout-register",
        component: UserInoutReport
    },
    {
        path: "organization-policy",
        component: OrganizationPolicy
    },
    {
        path: "organization-view",
        component: OrganizationView
    },
    {
        path: "my-profile",
        component: EmpInfoManagement
    },
]

const InternRoutes = ({ match }) => {
    return (
        <div className="gx-main-content-wrapper">
            <Switch>
                {internRoutes.map((route, index) => (
                    <Route
                        key={index}
                        exact
                        path={`${match.url}${route.path}`}
                        component={route.component}
                    />
                ))}
                <Redirect from="/" to="/dashboard" />
                <Redirect from="*" to="/my-in-out" />
            </Switch>
        </div>
    )
}

export default InternRoutes