


export const getResource = (name, types = []) => {
    const resourceData = JSON.parse(localStorage.getItem("resourceAccess"))
    const resource = resourceData.find(item => item.name === name);
 
    if (!resource) {
        console.log(`Resource '${name}' not found or not accessible.`);
        return false;
    }

    const hasAccess = types.every(type => resource[type] !== false );

    if (hasAccess) {
        console.log(`User has access to '${name}' for ${types.join(', ')}.`);
        return true;
    } else {
        console.log(`User does not have access to '${name}' for ${types.join(', ')}.`);
        return false;
    }
};