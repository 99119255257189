export const YearSelect = () => {
    // Get the current year
    const currentYear = new Date().getFullYear();
  
    // Generate an array of years from 2023 to the current year
    const years = [];
    for (let year = 2023; year <= currentYear; year++) {
      years.push(year);
    }
  
    // Map the years to the format required by Select options
    const yearOptions = years.map(year => ({ value: year.toString(), label: year.toString() }));
  
    return yearOptions
  };