import React from 'react'
import config from "../permission/config.json"
import { lazy } from 'react';
import {
  Redirect,
  Route,
} from "react-router-dom";
import ListingForResignation from "../components/Listing/ListingForResignation";
import LWDResign from "../components/Listing/LWDResign";
import ResignationFlow from "../components/Resignation/ResignationFlow";
import Wfhlisting from "../components/leave/addleave/Wfhlisting";
import UserResignationList from "../components/Resignation/UserResignationList";
import { getResource } from '../util/getResourcePermission';

const OrganizationPolicy = lazy(() =>
  import("../components/OrganizationPolicy/OrganizationPolicy")
);

const Internmaster = lazy(() =>
  import("../components/Internmaster/Internmaster")
);

const UserDashboard = lazy(() =>
  import("../components/UserDashboard/UserDashboard")
);

const Userreports = lazy(() => import("../components/UserReports/UserReport"));

const UserAttendenceReport = lazy(() =>
  import("../components/UserReports/AttendenceReport/UserAttendenceRegister")
);
const UserInoutReport = lazy(() =>
  import("../components/UserReports/AttendenceReport/UserInOutRegister")
);
const ManageKpis = lazy(() => import("../components/ManageKPI/ManageKpi"));
const ViewKpi = lazy(() => import("../components/Kpi/ViewKpi"));
const TeamLeaveCalander = lazy(() =>
  import("../components/TeamLeaveCalander/TeamLeaveCalander")
);
const ManageTeamRegularization = lazy(() =>
  import("../components/ManageTeamRegularization/ManageTeamRegularization")
);
const TeamRank = lazy(() => import("../components/TeamRank/TeamRank"));

const EmployeeOverview = lazy(() =>
  import("../components/EmpInfoManagement/EmployeeOverview")
);
const ReferEldiCandidate = lazy(() =>
  import("../components/Eldi/ReferEldiCandidate")
);
const AuditHistoryMonth = lazy(() =>
  import("../components/Audit/SuperAdminAuditHistory/AuditHistoryMonth")
);
const AuditHistoryOfEmp = lazy(() =>
  import("../components/Audit/AuditHistory/AuditHistoryOfEmp")
);
const CeoCompOffApprove = lazy(() =>
  import("../components/CeoCompoffApproval/CeoCompoffApproval")
);
const CEORegularization = lazy(() =>
  import("../components/CEORegurlarization/CEORegurlarization")
);
const SopMaster = lazy(() => import("../components/Audit/SopMaster/SopMaster"));
const Audits = lazy(() => import("../components/Audit/AuditHistory/Audits"));
const AuditMaster = lazy(() => import("../components/Audit/AuditMaster"));
const ViewReferCandidate = lazy(() =>
  import("../components/ReferCandidate/ViewReferCandidate")
);
const AppSettings = lazy(() => import("../components/AppSettings/AppSettings"));
const CandidateApproveReject = lazy(() =>
  import("../components/Onboarding/CandidateApproveReject")
);
const DocumentsApproveList = lazy(() =>
  import("../components/Onboarding/DocumentsApproveList")
);
const OnboardingInitiation = lazy(() =>
  import("../components/Onboarding/OnboardingInitiation")
);
const NewJoineesListAdmin = lazy(() =>
  import("../components/NewJoinees/NewJoineesListAdmin")
);
const NewJoineesForm = lazy(() =>
  import("../components/NewJoinees/NewJoineesForm")
);
const OrganizationView = lazy(() =>
  import("../components/OrganizationView/OrganizationView")
);
const NewAttendenceRegularizationList = lazy(() =>
  import(
    "../components/New-Attendence-regularization/NewAttendenceRegularizationList"
  )
);
const NewAttendenceRegularization = lazy(() =>
  import(
    "../components/New-Attendence-regularization/NewAttendenceRegularization"
  )
);
const EditMyGoals = lazy(() => import("../components/Goals/EditMyGoals"));
const EditGoals = lazy(() => import("../components/Goals/EditGoals"));
const AdminOnduty = lazy(() => import("../components/AdminOnduty/AdminOnduty"));
const Viewuserkpi = lazy(() => import("../components/Userkpi/Viewuserkpi"));
const Userkpi = lazy(() => import("../components/Userkpi/Userkpi"));
const Viewadminkpi = lazy(() => import("../components/Adminkpi/Viewadminkpi"));
const LeaveApproval = lazy(() =>
  import("../components/leave/addleave/LeaveApproval")
);
const LeaveApplication = lazy(() =>
  import("../components/leave/addleave/LeaveApplication")
);
const CompOffLeave = lazy(() =>
  import("../components/leave/addleave/CompOffLeave")
);
const AddLeave = lazy(() => import("../components/leave/addleave/AddLeave"));
const AddWfhRequest = lazy(() =>
  import("../components/leave/addleave/AddWfhRequest")
);
const AdminWfhRequestApprove = lazy(() =>
  import("../components/leave/addleave/AdminWfhRequestApprove")
);

const AdminLeaveApproval = lazy(() =>
  import("../components/leave/addleave/AdminLeaveApprovel")
);

const AdminDepartment = lazy(() =>
  import("../components/AdminDepartment/AdminDepartment")
);

const AdminDesignation = lazy(() =>
  import("../components/AdminDepartment/AdminDesignation")
);

const Holidays = lazy(() => import("../components/Holidays/ViewHolidays"));

const Employeesrank = lazy(() =>
  import("../components/EmployeeRank/EmployeeRank")
);

const Employeeleavebalance = lazy(() =>
  import("../components/Employeeleavebalance/Employeeleavebalance")
);

const AdminLeaveMaster = lazy(() =>
  import("../components/leave/addleave/AdminLeaveMaster")
);

const EmployeeMasterList = lazy(() =>
  import("../components/EmployeeMasterList/EmployeeMasterList")
);
const EmployeeInout = lazy(() =>
  import("../components/EmployeeInout/EmployeeInout")
);

const AdminRegularization = lazy(() =>
  import("../components/AdminRegurlarization/AdminRegurlarization")
);

const AdminCompOffApprove = lazy(() =>
  import("../components/leave/addleave/AdminCompOffApprove")
);
const AdminLeaveCancellation = lazy(() =>
  import("../components/leave/addleave/AdminLeaveCancellation")
);

const AdminDashboard = lazy(() =>
  import("../components/AdminDashboard/AdminDashboard")
);

const Exemployee = lazy(() =>
  import("../components/ExemployeeList/ExemployeeList")
);
const AddResignation = lazy(() =>
  import("../components/AddResignation/AddResignation")
);
const EditResignation = lazy(() =>
  import("../components/EditResignation/EditResignation.")
);

const MyInOut = lazy(() => import("../components/MyInOut/MyInOut"));
const AttendenceRegularization = lazy(() =>
  import("../components/AttendenceRegularization/AttendenceRegularization")
);

const AddEmployeeMaster = lazy(() =>
  import("../components/AddEmployeeMaster/AddEmployeeMaster")
);

const AddEmployeeRank = lazy(() =>
  import("../components/AddEmployeeRank/AddEmployeeRank")
);

const Adminreports = lazy(() => import("../components/Reports/Reports"));

const ReferCandidate = lazy(() =>
  import("../components/ReferCandidate/ReferCandidate")
);
const EmpLeftreport = lazy(() =>
  import("../components/Reports/Employee Report/EmployeeLeftReport")
);
const EmpJoiningreport = lazy(() =>
  import("../components/Reports/Employee Report/EmployeeJoiningReport")
);
const EmpDocreport = lazy(() =>
  import("../components/Reports/Employee Report/EmployeeDocAttendence")
);

const AttendenceReport = lazy(() =>
  import("../components/Reports/Attendence Report/AttendenceRegister")
);
const Attendenceregularization = lazy(() =>
  import("../components/Reports/Attendence Report/Attendenceregularization")
);
const InoutReport = lazy(() =>
  import("../components/Reports/Attendence Report/InOutRegister")
);
const MonthlyReport = lazy(() =>
  import("../components/Reports/Attendence Report/MonthlyReport")
);

const Adminkpi = lazy(() => import("../components/Adminkpi/Adminkpi"));

const LeaveApplicationReport = lazy(() =>
  import("../components/Reports/Leave Report/LeaveApplication")
);
const LeaveApprovalReport = lazy(() =>
  import("../components/Reports/Leave Report/LeaveApproval")
);

const LeaveBalanceReport = lazy(() =>
  import("../components/Reports/Leave Report/LeaveBalance")
);
const LeaveCompoffReport = lazy(() =>
  import("../components/Reports/Leave Report/CompOffList")
);
const AppoinmentLetter = lazy(() =>
  import("../components/Reports/Letter/AppoinmentLetter")
);
const ConfirmationLetter = lazy(() =>
  import("../components/Reports/Letter/ConfirmationLetter")
);

const EmployeeMasterView = lazy(() =>
  import("../components/EmployeeMasterView/EmployeeMasterView")
);

const viewexemployee = lazy(() =>
  import("../components/viewexemployee/ViewExEmpTab")
);

const AdminAddLeave = lazy(() =>
  import("../components/leave/addleave/AdminAddLeave")
);

const AdminAddCompoffApprove = lazy(() =>
  import("../components/leave/addleave/AdminAddCompoffApprove")
);
const CompOffLeaveApplication = lazy(() =>
  import("../components/leave/addleave/CompOffLeaveApplication")
);

const achivementsListing = lazy(() =>
  import("../components/Achivements/achivementList")
);
const addAchivement = lazy(() =>
  import("../components/Achivements/AddAchivement")
);
const editAchivement = lazy(() =>
  import("../components/Achivements/EditAchivement")
);

const policiesListing = lazy(() =>
  import("../components/policies/policiesList")
);
const addPolicy = lazy(() => import("../components/policies/addPolicy"));
const teamTicketAdmin = lazy(() =>
  import("../components/TeamTicketAdmin/TicketList"));
const TeamKpis = lazy(() => import("../components/TeamKpis/TeamKpis"));
const ticketListingTL = lazy(() =>
  import("../components/TeamTicket/TicketList"));
const ViewTicketTLtickets = lazy(() =>
  import("../components/ViewTicketTL/ViewTicketTL"));
const AdminAssetsMaster = lazy(() =>
  import("../components/Inventory/AdminAssetsMaster"));
const Assets = lazy(() => import("../components/Inventory/Assets"));
const UserAssets = lazy(() => import("../components/UserInventory/Assets"));
const addAsset = lazy(() =>
  import("../components/InventoryTabpane/AddInventoryMaster"));
const editAsset = lazy(() =>
  import("../components/Inventory/EditInventoryMaster"));
const TestKpi = lazy(() => import("../components/TestKpi/TestKpi"));
const ViewTeamKpi = lazy(() => import("../components/TeamKpis/ViewTeamKpi"));
const ticketListing = lazy(() => import("../components/Ticket/TicketList"));
const AddTicket = lazy(() => import("../components/Ticket/AddTicket"));
const EditTicket = lazy(() => import("../components/EditTicket/EditTicket"));
const ViewTicket = lazy(() => import("../components/ViewTicket/ViewTicket"));
const AddGoals = lazy(() => import("../components/Goals/AddGoals"));
const GoalListing = lazy(() => import("../components/Goals/GoalListing"));
const GoalReports = lazy(() => import("../components/GoalReports/GoalReports"));
const ViewUserTicket = lazy(() =>
  import("../components/ViewTicket/viewTicketAsAdmin"));
const editPolicy = lazy(() => import("../components/policies/editPolicy"));
const JobOpeningList = lazy(() =>
  import("../components/JobOpenings/JobOpeningList"));
const AddJob = lazy(() => import("../components/JobOpenings/AddJob"));
const EditJob = lazy(() => import("../components/JobOpenings/EditJob"));
const ViewJob = lazy(() => import("../components/ViewJob/ViewJob"));
const assetTab = lazy(() => import("../components/InventoryTabpane/Asset"));
const EmpInfoManagement = lazy(() =>
  import("../components/EmpInfoManagement/EmpInfoManagement"));
const NewAudit = lazy(() =>
  import("../components/Audit/AuditHistory/NewAudit"));
const AuditHistoryView = lazy(() =>
  import("../components/Audit/AuditHistory/AuditHistoryView"));
const AuditPerformance = lazy(() =>
  import("../components/Audit/AuditPerformance"));
const AuditHead = lazy(() =>
  import("../components/Audit/AuditHead"));
const ViewAudits = lazy(() =>
  import("../components/Audit/SuperAdminAuditHistory/ViewAudits"));
const SuperAdminAuditHistory = lazy(() =>
  import("../components/Audit/SuperAdminAuditHistory/SuperAdminAuditHistory"));
const EditAudit = lazy(() =>
  import("../components/Audit/AuditHistory/EditAudit"));
const PendingTask = lazy(() => import("../components/PendingTask/PendingTask"));
const AddResignationForUSer = lazy(() =>
  import("../components/AdminSideUserResignation/AdminSideUserResignationFlow"));
const QuaterUserRanking = lazy(() =>
  import("../components/IndividualquaterlyRank/QuaterUserRanking"));
const PermissionModule = lazy(() =>
  import("../components/Permisson/PermissionList"));
const PermissionAssignModule = lazy(() =>
  import("../components/Permisson/AssignPermission"));
const AddTeamRank = lazy(() => import("../components/AddTeamRank/AddTeamRank"));
const AdminLeaveApprove = lazy(() =>
  import("../components/leave/addleave/AdminLeaveApprove"));
const WfhRequestApprove = lazy(() =>
  import("../components/leave/addleave/WfhRequestApprove"));
const ticketListingTLMain = lazy(() =>
  import("../components/TeamTicketMaintl/TicketList"));
const ViewTeamTicket = lazy(() =>
  import("../components/ViewTicket/ViewTeamTicket"));
const CompOffApprove = lazy(() =>
  import("../components/leave/addleave/CompOffApprove"));
const LeaveCancellation = lazy(() =>
  import("../components/leave/addleave/LeaveCancellation"));
const EditLeaveCancellation = lazy(() =>
  import("../components/leave/addleave/EditLeaveCancellation"));
const LeaveCancellationApproval = lazy(() =>
  import("../components/leave/addleave/LeaveCancellationApproval"));
const DirectorRegularization = lazy(() =>
  import("../components/DirectorRegularization/DirectorRegularization"));
const DirectorCompOffApprove = lazy(() =>
  import("../components/DirectorCompoffApproval/DirectorCompoffApproval"));
const Kpi = lazy(() => import("../components/Kpi/Kpi"));
const NewJoineesListTL = lazy(() =>
  import("../components/NewJoinees/NewJoineesListTL"));
const AuditHistoryOfTL = lazy(() =>
  import("../components/Audit/AuditHistory/AuditHistoryOfTL"));
const TeamInoutReport = lazy(() =>
  import("../components/TeamReports/AttendenceReport/UserInOutRegister"));
const TeamAttendenceReport = lazy(() =>
  import("../components/TeamReports/AttendenceReport/UserAttendenceRegister"));
const Teamreports = lazy(() =>
  import("../components/TeamReports/UserReport"));
const GoalRecords = lazy(() =>
  import("../components/Goals/GoalRecords"));
const EmpReferListing = lazy(() =>
  import("../components/Eldi/EmpReferListing")
);
const EmpDetails = lazy(() =>
  import("../components/EmpDetails/EmpDetails")
);

const NewRoutes = ({ match }) => {

  const userdata = JSON.parse(localStorage.getItem("user_data"))
  let routeArray = [
    {
      path: "my-in-out",
      component: MyInOut,
      permission: [],
    },
    {
      path: "dashboard",
      component:
        userdata?.role_id?.role_name == "Admin" || userdata?.role_id?.role_name == "HR"
          ? AdminDashboard
          : UserDashboard,
      permission: [],
    },

    {
      path: "user-reports",
      component: Userreports,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      path: "user-reports/attendence-reports/attendence-register",
      component: UserAttendenceReport,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      path: "user-reports/attendence-reports/inout-register",
      component: UserInoutReport,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      path: "attendence-reports/attendence-register",
      component: AttendenceReport,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      path: "attendence-reports/inout-register",
      component: InoutReport,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      path: "organization-policy",
      component: OrganizationPolicy,
      permission: [],
    },
    {
      path: "organization-view",
      component: OrganizationView,
      permission: [],
    },
    {
      path: "resignation-employee-list",
      component: ListingForResignation,
      permission: [config.RESOURCES.SEPARATION_MANAGEMENT],
    },
    {
      path: "last-working-day-emps",
      component: LWDResign,
      permission: [config.RESOURCES.LAST_WORKING_DAY_LIST],
    },
    {
      path: "resignation",
      component: ResignationFlow,
      permission: [config.RESOURCES.SEPARATION],
    },
    {
      path: "refer-candidate",
      component: ReferCandidate,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      path: "view-refer-candidate/:id",
      component: ViewReferCandidate,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      path: "employee-overview/:id",
      component: EmployeeOverview,
      permission: [],
    },
    {
      path: "employee-overview",
      component: EmployeeOverview,
      permission: [],
    },
    {
      path: "refer-eldi-admin",
      component: ReferEldiCandidate,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      path: "employee-attendence-regularization",
      component: AttendenceRegularization,
      permission: [config.RESOURCES.ATTENDANCE_REGULARIZATION],
    },
    // {
    //   path: "/organization-view",
    //   component: OrganizationView,
    //   roleName: [config.ROLES.NETWORK_TEAM, config.ROLES.USER, config.ROLES.ADMIN, config.ROLES.HR, config.ROLES.REPORTING_MANAGER, config.ROLES.INTERN,config.ROLES.AUDITOR]
    // },
    {
      path: "pending-task-list",
      component: PendingTask,
      permission: [config.RESOURCES.PENDING_TASK],
    },
    {
      path: "new-joinees-form",
      component: NewJoineesForm,
      permission: [config.RESOURCES.NEW_JOINEES],
    },

    {
      path: "new-joinees-list",
      component: NewJoineesListAdmin,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      path: "onboarding-initiation",
      component: OnboardingInitiation,
      permission: [config.RESOURCES.ON_BOARDING_INITIATION],
    },
    {
      path: "doc-approve-list",
      component: DocumentsApproveList,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      path: "doc-approve-reject/:id",
      component: CandidateApproveReject,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      path: "adminonduty",
      component: AdminOnduty,
      permission: [config.RESOURCES.ON_DUTY_REGULARIZATION],
    },
    {
      path: "user-leave-approve",
      component: LeaveApproval,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      path: "inventory",
      component: assetTab,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      path: "create-goals",
      component: AddGoals,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      path: "edit-goals/:id",
      component: EditGoals,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      path: "edit-my-goals/:id",
      component: EditMyGoals,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      path: "goals",
      component: GoalListing,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      path: "goal-reports",
      component: GoalReports,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      path: "employee-master",
      component: EmployeeMasterList,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      path: "my-profile",
      component: EmpInfoManagement,
      permission: [],
    },
    {
      path: "admin-compoff-approval",
      component: AdminCompOffApprove,
      permission: [config.RESOURCES.C_OFF_MANAGEMENT],
    },
    {
      path: "admin-leave-master",
      component: AdminLeaveMaster,
      permission: [config.RESOURCES.LEAVE_MASTER],
    },
    {
      path: "admin-assets-master",
      component: AdminAssetsMaster,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      path: "admin-assets",
      component: Assets,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      path: "admin-assets/add-asset",
      component: addAsset,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      path: "admin-assets/edit-asset/:id",
      component: editAsset,
      permission: [config.RESOURCES.INVENTORY],
    },
    {
      path: "user-assets",
      component: UserAssets,
      permission: [config.RESOURCES.MY_ASSETS],
    },
    {
      path: "designation-kpis",
      component: Adminkpi,
      permission: [],
    },
    {
      path: "user-kpis",
      component: Userkpi,
      permission: [],
    },
    {
      path: "view-designation-kpis/:id",
      component: Viewadminkpi,
      permission: [],
    },
    {
      path: "view-user-kpi/:id",
      component: Viewuserkpi,
      permission: [],
    },
    {
      path: "view-team-kpi/:id",
      component: ViewTeamKpi,
      permission: [],
    },
    {
      path: "admin-leave-cancellation",
      component: AdminLeaveCancellation,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      path: "admin-leave-approval",
      component: AdminLeaveApproval,
      permission: [config.RESOURCES.ADMIN_LEAVE_MANAGEMENT],
    },
    {
      path: "admin-department",
      component: AdminDepartment,
      permission: [config.RESOURCES.DEPARTMENTS],
    },
    {
      path: "admin-designation",
      component: AdminDesignation,
      permission: [config.RESOURCES.DESIGNATION],
    },
    {
      path: "holidays",
      component: Holidays,
      permission: [config.RESOURCES.HOLIDAYS],
    },
    {
      path: "admin-dashboard",
      component: AdminDashboard,
      permission: [],
    },
    {
      path: "employee-leave-balance",
      component: Employeeleavebalance,
      permission: [config.RESOURCES.MANAGE_LEAVE_BALANCE],
    },
    {
      path: "admin-add-compoff-application",
      component: AdminAddCompoffApprove,
      permission: [config.RESOURCES.C_OFF_MANAGEMENT],
    },
    {
      path: "employee-master/add-employee-master",
      component: AddEmployeeMaster,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      path: "employee-master/update-employee-master/:id",
      component: EmpInfoManagement,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      path: "employee-master/view-employee-master/:id",
      component: EmployeeMasterView,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      path: "ex-employee/view-resignation/:id",
      component: viewexemployee,
      permission: [config.RESOURCES.EX_EMPLOYEE],
    },
    {
      path: "admin-reports",
      component: Adminreports,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      path: "manage-tickets",
      component: ticketListingTL,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      path: "manage-tickets/view-ticket/:id",
      component: ViewTicketTLtickets,
      permission: [config.RESOURCES.MANAGE_TICKET],
    },
    {
      path: "achivements",
      component: achivementsListing,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      path: "permission-manager",
      component: PermissionModule,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      path: "permission-manager/:id",
      component: PermissionAssignModule,
      permission: [config.RESOURCES.SETTINGS],
    },
    {
      path: "policies",
      component: policiesListing,
      permission: [config.RESOURCES.POLICIES],
    },
    {
      path: "policies/add-policy",
      component: addPolicy,
      permission: [config.RESOURCES.POLICIES],
    },
    {
      path: "policies/edit-policy/:id",
      component: editPolicy,
      permission: [config.RESOURCES.POLICIES],
    },
    {
      path: "achivements/add-achivement",
      component: addAchivement,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      path: "achivements/update-achivement/:id",
      component: editAchivement,
      permission: [config.RESOURCES.ACHIEVEMENTS],
    },
    {
      path: "employee-tickets",
      component: teamTicketAdmin,
      permission: [config.RESOURCES.EMPLOYEE_TICKET],
    },
    {
      path: "team-kpis",
      component: TeamKpis,
      permission: [],
    },
    {
      path: "Kpis",
      component: TestKpi,
      permission: [],
    },
    {
      path: "tickets",
      component: ticketListing,
      permission: [config.RESOURCES.TICKET],
    },
    {
      path: "tickets/add-ticket",
      component: AddTicket,
      permission: [config.RESOURCES.TICKET],
    },
    {
      path: "tickets/update-ticket/:id",
      component: EditTicket,
      permission: [config.RESOURCES.TICKET],
    },
    {
      path: "tickets/view-ticket/:id",
      component: ViewTicket,
      permission: [config.RESOURCES.TICKET],
    },
    {
      path: "employee-tickets/view-ticket/:id",
      component: ViewUserTicket,
      permission: [config.RESOURCES.TICKET],
    },
    {
      path: "employee-reports/emp-left-report",
      component: EmpLeftreport,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      path: "employee-reports/emp-joining-report",
      component: EmpJoiningreport,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      path: "employee-reports/emp-doc-report",
      component: EmpDocreport,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      path: "admin-attendence-reports/attendence-register",
      component: AttendenceReport,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      path: "attendence-reports/attendence-regularization",
      component: Attendenceregularization,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      path: "employee-attendence-reports/inout-register",
      component: InoutReport,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      path: "attendence-reports/monthly-register",
      component: MonthlyReport,
      permission: [config.RESOURCES.ATTENDANCE_REPORT],
    },
    {
      path: "leave-reports/leave-application",
      component: LeaveApplicationReport,
      permission: [config.RESOURCES.LEAVE_REPORT],
    },
    {
      path: "leave-reports/leave-approval",
      component: LeaveApprovalReport,
      permission: [config.RESOURCES.LEAVE_REPORT],
    },
    {
      path: "leave-reports/leave-balance",
      component: LeaveBalanceReport,
      permission: [config.RESOURCES.LEAVE_REPORT],
    },
    {
      path: "leave-reports/comp-off-leave",
      component: LeaveCompoffReport,
      permission: [config.RESOURCES.LEAVE_REPORT],
    },
    {
      path: "letter/appointment-letter",
      component: AppoinmentLetter,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      path: "letter/confirmation-letter",
      component: ConfirmationLetter,
      permission: [config.RESOURCES.ON_BOARDING_DOC_APPROVAL_LIST],
    },
    {
      path: "ex-employee",
      component: Exemployee,
      permission: [config.RESOURCES.EX_EMPLOYEE],
    },
    {
      path: "ex-employee/add-resignation",
      component: AddResignation,
      permission: [config.RESOURCES.EX_EMPLOYEE],
    },
    {
      path: "ex-employee/update-resignation/:id",
      component: EditResignation,
      permission: [config.RESOURCES.EX_EMPLOYEE],
    },   
    {
      path: "employee-in-out",
      component: EmployeeInout,
      permission: [config.RESOURCES.EMPLOYEE_IN_OUT],
    },
    {
      path: "employee-ranking",
      component: Employeesrank,
      permission: [config.RESOURCES.EMPLOYEE_RANKING],
    },
    {
      path: "add-resignation-for-user",
      component: AddResignationForUSer,
      permission: [config.RESOURCES.SEPARATION],
    },
    {
      path: "add-employee-rank",
      component: AddEmployeeRank,
      permission: [config.RESOURCES.EMPLOYEE_RANKING],
    },
    {
      path: "admin-regularizatrion",
      component: AdminRegularization,
      permission: [config.RESOURCES.ADMIN_REGULARIZATION],
    },
    {
      path: "admin-work-from-home-approval",
      component: AdminWfhRequestApprove,
      permission: [config.RESOURCES.WHF_MANAGEMENT],
    },
    {
      path: "jobs/openings",
      component: JobOpeningList,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      path: "jobs/add",
      component: AddJob,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      path: "jobs/edit/:jobId",
      component: EditJob,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      path: "jobs/view/:id",
      component: ViewJob,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      path: "emp-info-management",
      component: EmpInfoManagement,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      path: "auditmaster",
      component: AuditMaster,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "sopmaster/:departmentId",
      component: SopMaster,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "audits",
      component: Audits,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "audit-history/:employeeId",
      component: AuditHistoryOfEmp,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "team-audit-history",
      component: AuditHistoryOfEmp,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "admin-audit-history-month",
      component: AuditHistoryView,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "new-audit/:employeeId",
      component: NewAudit,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "edit-audit",
      component: EditAudit,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "auditperformance",
      component: AuditPerformance,
      permission: [config.RESOURCES.AUDITS_PERFORMANCE],
    },
    {
      path: "audithead",
      component: AuditHead,
      permission: [config.RESOURCES.AUDIT_HEAD],
    },
    {
      path: "viewaudits",
      component: ViewAudits,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "audithistory/:employeeId",
      component: SuperAdminAuditHistory,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "audit-month",
      component: AuditHistoryMonth,
      permission: [config.RESOURCES.AUDIT_MASTER],
    },
    {
      path: "audit-history-month",
      component: AuditHistoryMonth,
      permission: [config.RESOURCES.AUDITS],
    },
    {
      path: "individual-rank",
      component: QuaterUserRanking,
      permission: [config.RESOURCES.RANKING],
    },
    {
      path: "leave-application",
      component: LeaveApplication,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "leave-application/add-leave",
      component: AddLeave,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "admin-leave-approval/add-leave",
      component: AdminAddLeave,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "compoff-leave-application",
      component: CompOffLeaveApplication,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "compoff-leave-application/add-compoff-leave",
      component: CompOffLeave,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "add-work-from-home-application",
      component: AddWfhRequest,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "resignation-userlist",
      component: UserResignationList,
      permission: [config.RESOURCES.SEPARATION],
    },
    {
      path: "work-from-home-application",
      component: Wfhlisting,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "newattendencelist",
      component: NewAttendenceRegularizationList,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "newattendence",
      component: NewAttendenceRegularization,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "app-setting",
      component: AppSettings,
      permission: [],
    },
    {
      path: "kpis",
      component: ManageKpis,
      permission: [],
    },
    {
      path: "view-team-kpi/:id",
      component: ViewTeamKpi,
      permission: [],
    },
    {
      path: "view-kpi/:id`",
      component: ViewKpi,
      permission: [],
    },
    {
      path: "team-leave-calender",
      component: TeamLeaveCalander,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      path: "manage-team-regularization",
      component: ManageTeamRegularization,
      permission: [config.RESOURCES.TEAM_REGULARIZATION],
    },
    {
      path: "team-ranking",
      component: TeamRank,
      permission: [config.RESOURCES.RANKING],
    },
    {
      path: "add-team-rank",
      component: AddTeamRank,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
    {
      path: "add-team-employee-rank",
      component: AddTeamRank,
      permission: [config.RESOURCES.EMPLOYEE_RANKING],
    },
    {
      path: "admin-approve",
      component: AdminLeaveApprove,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      path: "work-from-home-approval",
      component: WfhRequestApprove,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      path: "leave-reports/",
      component: LeaveApplicationReport,
      permission: [config.RESOURCES.LEAVE_REPORT],
    },
    {
      path: "team-tickets",
      component: ticketListingTLMain,
      permission: [config.RESOURCES.TICKET],
    },
    {
      path: "team-tickets/view-ticket/:id",
      component: ViewTeamTicket,
      permission: [config.RESOURCES.TICKET],
    },
    {
      path: "compoff-approve",
      component: CompOffApprove,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      path: "ceo-compoff-approval",
      component: CeoCompOffApprove,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      path: "ceo-regularizatrion",
      component: CEORegularization,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "leave-cancellation",
      component: LeaveCancellation,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "leave-cancellation/:id",
      component: EditLeaveCancellation,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "leave-cancellation-approve",
      component: LeaveCancellationApproval,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },
    {
      path: "director-regularizatrion",
      component: DirectorRegularization,
      permission: [config.RESOURCES.LEAVE],
    },
    {
      path: "director-compoff-approval",
      component: DirectorCompOffApprove,
      permission: [config.RESOURCES.LEAVE_MANAGEMENT],
    },

    //ceo
    {
      path: "user-eldi-refer",
      component: EmpReferListing,
      permission: [config.RESOURCES.JOB_OPENING],
    },
    {
      path: "goal-records/:goal_id",
      component: GoalRecords,
      permission: [config.RESOURCES.MY_GOAL],
    },
    {
      path: "team-reports",
      component: Teamreports,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      path: "team-reports/attendence-reports/attendence-register",
      component: TeamAttendenceReport,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      path: "team-reports/attendence-reports/inout-register",
      component: TeamInoutReport,
      permission: [config.RESOURCES.EMPLOYEE_REPORT],
    },
    {
      path: "audit-history",
      component: AuditHistoryOfTL,
      permission: [config.RESOURCES.AUDITS],
    },
    {
      path: "new-joinees",
      component: NewJoineesListTL,
      permission: [config.RESOURCES.NEW_JOINEES],
    },
    {
      path: "kpis",
      component: Kpi,
      permission: [],
    },
    {
      path: "viewEmployeeDetails",
      component: EmpDetails,
      permission: [config.RESOURCES.EMPLOYEE_DETAILS],
    },
    {
      path: "intern-master",
      component: Internmaster,
      permission: [config.RESOURCES.EMPLOYEE_MASTER],
    },
  ];


  return (
    <>
      {routeArray.map((item, index) => {
        let permission = item?.permission?.length > 0 ? getResource(item?.permission[0],['read']) : true 
        return (
          <Route
            exact
            key={index}
            path={`${match.url}${item.path}`}
            render={(
              routeProps
            ) =>
               permission ? (
                React.createElement(item.component, { ...routeProps })
              ) : (
                <Redirect to="/dashboard" />
              )
            }
          />
        )
      })}
    </>
  )
}

export default NewRoutes