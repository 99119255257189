import {
  Card,
  Table,
  Tag,
  Input,
  Button,
  Modal,
  Form,
  Select,
  message,
  Tooltip,
  DatePicker,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import Service from "../../service";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { FormOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { YearSelect } from "../../util/YearOptions";


function UserResignationList() {
  let { authUser } = useSelector(({ auth }) => auth);
  const [isModalOpenView, setIsModalOpenView] = useState(false);
  const [commentsView, setcomments] = useState("");
  const [reasonView, setReason] = useState("");
  const [showreasonRevoke, setshowReasonRevoke] = useState("");
  const [showReasonCancel, setshowReasonCancel] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [isModalOpenFilter, setIsModalOpenFilter] = useState(false);
  const [seachEnabled, setSearchEnabled] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [Employees, setEmployees] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const { Option } = Select;
  const Search = Input.Search;
  const searchRef = useRef();
  useEffect(() => {
    getEmployeesResignList();
  }, [pagination.current, pagination.pageSize, searchText]);

  const showModalView = (id, comments, reason, revoke, cancel) => {
    setIsModalOpenView(true);
    setcomments(comments);
    setReason(reason);
    setshowReasonRevoke(revoke);
    setshowReasonCancel(cancel);
  };
  const handleOkView = () => {
    setIsModalOpenView(false);
  };
  const handleCancelView = () => {
    setIsModalOpenView(false);
    setcomments("");
    setReason("");
    setshowReasonRevoke("");
    setshowReasonCancel("");
  };

  const onSearch = (value) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };
  const resetSearchFilter = (e) => {
    const keyCode = e && e.keyCode ? e.keyCode : e;
    switch (keyCode) {
      case 8:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      case 46:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      default:
        break;
    }
  };
  const getEmployeesResignList = async (filterData) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        pageNum: pagination.current,
        pageLimit: pagination.pageSize,
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        setSearchEnabled(true);
      }

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.userResignList,
        body: reqBody,
      });

      dispatch(hideAuthLoader());
      if (response?.data?.data?.length > 0) {
        if (filterData?.isActive == true) {
          setPagination({
            ...pagination,
            total: response.data.metaData.totalFilteredCount,
            deactivetotal: response.data.metaData.totaldUsers,
          });
        } else {
          setPagination({
            ...pagination,
            total: response.data.metaData.totalFilteredCount,
          });
        }
        setEmployees(response.data.data);
      } else {
        setPagination({
          ...pagination,
          total: 0,
        });
        setEmployees([]);
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Employee Code",
      dataIndex: "emp_code",
      key: "emp_code",
      render: (text, record, index) => {
        return <span>{record?.emp_code}</span>;
      },
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (text, record, index) => {
        return <span>{record?.employee_name}</span>;
      },
    },
    {
      title: "Date Of Resignation",
      dataIndex: "date_of_resignation",
      key: "date_of_resignation",
      render: (text, record, index) => {
        return (
          <span>{moment(record?.resignation_date).format("DD MMM YYYY")}</span>
        );
      },
    },
    {
      title: "Last Date As Per Notice Period",
      dataIndex: "last_date_notice",
      key: "last_date_notice",
      render: (text, record, index) => {
        return (
          <span>{moment(record?.last_date_notice).format("DD MMM YYYY")}</span>
        );
      },
    },
    {
      title: "Requested Last Date",
      dataIndex: "request_last_date",
      key: "request_last_date",
      render: (text, record, index) => {
        return (
          <span>{record?.request_last_date ? moment(record?.request_last_date).format("DD MMM YYYY") : " - "}</span>
        );
      },
    },
    {
      title: "Last Date",
      dataIndex: "last_date",
      key: "last_date",
      render: (text, record, index) => {
        if (record?.last_date) {
          return <span>{moment(record?.last_date).format("DD MMM YYYY")}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: "Accepted Date",
      dataIndex: "Accepted Date",
      key: "Accepted Date",
      render: (text, record, index) => {
        const AcceptedRecord = record.statusHistory.find(
          (history) => history.resignationStatus === "Accepted"
        );
        if (AcceptedRecord) {
          return (
            <span>
              {moment(AcceptedRecord.created_At).format("DD MMM YYYY")}
            </span>
          );
        } else {
          return <span>-</span>;
        }
      },
    },

    {
      title: "Rejected Date",
      dataIndex: "Rejected Date",
      key: "Rejected Date",
      render: (text, record, index) => {
        const RejectedRecord = record.statusHistory.find(
          (history) => history.resignationStatus === "Rejected"
        );
        if (RejectedRecord) {
          return (
            <span>
              {moment(RejectedRecord.created_At).format("DD MMM YYYY")}
            </span>
          );
        } else {
          return <span>-</span>;
        }
      },
    },

    {
      title: "Cancelled Date",
      dataIndex: "Cancelled",
      key: "Cancelled",
      render: (text, record, index) => {
        const cancelledRecord = record.statusHistory.find(
          (history) => history.resignationStatus === "Cancelled"
        );

        if (cancelledRecord) {
          return (
            <span>
              {moment(cancelledRecord.created_At).format("DD MMM YYYY")}
            </span>
          );
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: "Attachments",
      dataIndex: "FeedbackAttachment",
      key: "FeedbackAttachment",
      render: (text, record, index) => {
        if (record?.FeedbackAttachment === "") {
          <span>-</span>;
        }
        return (
          <span>
            <a
              href={`${Service.Server_Base_URL}/uploads/Feedbacks/${record?.FeedbackAttachment}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record?.FeedbackAttachment}
            </a>
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        const status = record?.resignationStatus;
        const color =
          record?.resignationStatus == "Pending"
            ? "processing"
            : record?.resignationStatus == "Accepted"
              ? "success"
              : "error";
        return status ? <Tag color={color}>{status}</Tag> : null;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            flexwrap: "wrap",
          }}
        >
          {
            <Tooltip title="Comments">
              <Button
                type="link success"
                onClick={() =>
                  showModalView(
                    record._id,
                    record?.comments,
                    record?.reason,
                    record?.reasonRevoke,
                    record?.reasonCancel
                  )
                }
              >
                <EyeOutlined style={{ fontSize: "18px" }} />
              </Button>
            </Tooltip>
          }


        </div>
      ),
    },
  ];



  const handleTableChange = (page, filters, sorter) => {
    setPagination({ ...pagination, ...page });
  };
  const getFooterDetails = () => {
    return (
      <label>
        Total Records Count is {pagination.total > 0 ? pagination.total : 0}
      </label>
    );
  };
  return (
    <div>
      <Modal
        title="Reasons"
        open={isModalOpenView}
        footer={false}
        onOk={handleOkView}
        onCancel={handleCancelView}
      >
        Reason for resignation : {reasonView === ' ' ? '-' : reasonView}
        <br />
        <br />
        Comments for resignation : {commentsView === ' ' ? '-' : commentsView}
        <br />
        <br />
        Reason for revoking : {showreasonRevoke === ' ' ? '-' : showreasonRevoke}
        <br />
        <br />
        Reason for Cancelling : {showReasonCancel === ' ' ? '-' : showReasonCancel}
      </Modal>
      <Card title="My Resignation data">
        <div className="steps-content">
          <Search
            ref={searchRef}
            placeholder="Search..."
            onSearch={onSearch}
            onKeyUp={resetSearchFilter}
            style={{ width: 200 }}
            className="mr2"
          />

          <Table
            columns={columns}
            footer={getFooterDetails}
            onChange={handleTableChange}
            dataSource={Employees}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              ...pagination,
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default UserResignationList;
