import React, { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  LAST_WORKING_DAY_LIST,
  NETWORK_TEAM_ROLE_ID,
  NOT_HAVE_MANAGE_TICKET_ACCESS,
  ONBOARDING_MODULE_ACCESS,
  MANAGE_TICKET_ACCESS,
} from "../permission/access";
import { UserSuperModuleRoutes } from "./UserSuperModuleRoutes";
import ResignationFlow from "../components/Resignation/ResignationFlow";
import ListingForResignation from "../components/Listing/ListingForResignation";
import LWDResign from "../components/Listing/LWDResign";
import UserResignationList from "../components/Resignation/UserResignationList";
import DocumentsApproveList from "../components/Onboarding/DocumentsApproveList";

const EmployeeOverview = lazy(() =>
  import("../components/EmpInfoManagement/EmployeeOverview")
);

const AuditHistoryMonth = lazy(() =>
  import("../components/Audit/SuperAdminAuditHistory/AuditHistoryMonth")
);

const ViewReferCandidate = lazy(() =>
  import("../components/ReferCandidate/ViewReferCandidate")
);

const OrganizationView = lazy(() =>
  import("../components/OrganizationView/OrganizationView")
);
const NewAttendenceRegularizationList = lazy(() =>
  import(
    "../components/New-Attendence-regularization/NewAttendenceRegularizationList"
  )
);
const NewAttendenceRegularization = lazy(() =>
  import(
    "../components/New-Attendence-regularization/NewAttendenceRegularization"
  )
);
const EditMyGoals = lazy(() => import("../components/Goals/EditMyGoals"));
const EditGoals = lazy(() => import("../components/Goals/EditGoals"));

const LeaveApproval = lazy(() =>
  import("../components/leave/addleave/LeaveApproval")
);
const LeaveApplication = lazy(() =>
  import("../components/leave/addleave/LeaveApplication")
);
const CompOffLeave = lazy(() =>
  import("../components/leave/addleave/CompOffLeave")
);
const AddLeave = lazy(() => import("../components/leave/addleave/AddLeave"));
const QuaterUserRanking = lazy(() =>
  import("../components/IndividualquaterlyRank/QuaterUserRanking")
);

const NewJoineesListTL = lazy(() =>
  import("../components/NewJoinees/NewJoineesListTL")
);
const LeaveCancellation = lazy(() =>
  import("../components/leave/addleave/LeaveCancellation")
);
const EditLeaveCancellation = lazy(() =>
  import("../components/leave/addleave/EditLeaveCancellation")
);
const CompOffApprove = lazy(() =>
  import("../components/leave/addleave/CompOffApprove")
);
const AdminLeaveApprove = lazy(() =>
  import("../components/leave/addleave/AdminLeaveApprove")
);

const AuditHistoryOfTL = lazy(() =>
  import("../components/Audit/AuditHistory/AuditHistoryOfTL")
);
const EmpReferListing = lazy(() =>
  import("../components/Eldi/EmpReferListing")
);

const Wfhlisting = lazy(() =>
  import("../components/leave/addleave/Wfhlisting")
);
const AddWfhRequest = lazy(() =>
  import("../components/leave/addleave/AddWfhRequest")
);
const WfhRequestApprove = lazy(() =>
  import("../components/leave/addleave/WfhRequestApprove")
);

const AddTeamRank = lazy(() => import("../components/AddTeamRank/AddTeamRank"));

const TeamRank = lazy(() => import("../components/TeamRank/TeamRank"));

const OrganizationPolicy = lazy(() =>
  import("../components/OrganizationPolicy/OrganizationPolicy")
);

const UserDashboard = lazy(() =>
  import("../components/UserDashboard/UserDashboard")
);

const EmployeeMasterList = lazy(() =>
  import("../components/EmployeeMasterList/EmployeeMasterList")
);
const EmployeeInout = lazy(() =>
  import("../components/EmployeeInout/EmployeeInout")
);

const LeaveCancellationApproval = lazy(() =>
  import("../components/leave/addleave/LeaveCancellationApproval")
);

const MyInOut = lazy(() => import("../components/MyInOut/MyInOut"));
const AttendenceRegularization = lazy(() =>
  import("../components/AttendenceRegularization/AttendenceRegularization")
);
const ManageTeamRegularization = lazy(() =>
  import("../components/ManageTeamRegularization/ManageTeamRegularization")
);

//Admin-Reports

const Userreports = lazy(() => import("../components/UserReports/UserReport"));
const Teamreports = lazy(() => import("../components/TeamReports/UserReport"));

const ReferCandidate = lazy(() =>
  import("../components/ReferCandidate/ReferCandidate")
);

const UserAttendenceReport = lazy(() =>
  import("../components/UserReports/AttendenceReport/UserAttendenceRegister")
);
const UserInoutReport = lazy(() =>
  import("../components/UserReports/AttendenceReport/UserInOutRegister")
);

const TeamAttendenceReport = lazy(() =>
  import("../components/TeamReports/AttendenceReport/UserAttendenceRegister")
);
const TeamInoutReport = lazy(() =>
  import("../components/TeamReports/AttendenceReport/UserInOutRegister")
);

const TeamLeaveCalander = lazy(() =>
  import("../components/TeamLeaveCalander/TeamLeaveCalander")
);

//leave module

const CompOffLeaveApplication = lazy(() =>
  import("../components/leave/addleave/CompOffLeaveApplication")
);

//Ticket Module

const TeamKpis = lazy(() => import("../components/TeamKpis/TeamKpis"));

const ManageKpis = lazy(() => import("../components/ManageKPI/ManageKpi"));

const ticketListingTL = lazy(() =>
  import("../components/TeamTicket/TicketList")
);

const ticketListingTLMain = lazy(() =>
  import("../components/TeamTicketMaintl/TicketList")
);

const ViewTicketTLtickets = lazy(() =>
  import("../components/ViewTicketTL/ViewTicketTL")
);

//inventory module

const AdminAssetsMaster = lazy(() =>
  import("../components/Inventory/AdminAssetsMaster")
);

const Assets = lazy(() => import("../components/Inventory/Assets"));

const UserAssets = lazy(() => import("../components/UserInventory/Assets"));

const addAsset = lazy(() =>
  import("../components/InventoryTabpane/AddInventoryMaster")
);

const editAsset = lazy(() =>
  import("../components/Inventory/EditInventoryMaster")
);
const Kpi = lazy(() => import("../components/Kpi/Kpi"));

const ViewKpi = lazy(() => import("../components/Kpi/ViewKpi"));

const ViewTeamKpi = lazy(() => import("../components/TeamKpis/ViewTeamKpi"));

const ticketListing = lazy(() => import("../components/Ticket/TicketList"));

const AddTicket = lazy(() => import("../components/Ticket/AddTicket"));

const EditTicket = lazy(() => import("../components/EditTicket/EditTicket"));

const ViewTicket = lazy(() => import("../components/ViewTicket/ViewTicket"));
const AddGoals = lazy(() => import("../components/Goals/AddGoals"));
const GoalListing = lazy(() => import("../components/Goals/GoalListing"));
const GoalRecords = lazy(() => import("../components/Goals/GoalRecords"));

// assigned sub goals

const GoalReports = lazy(() => import("../components/GoalReports/GoalReports"));

const ViewTeamTicket = lazy(() =>
  import("../components/ViewTicket/ViewTeamTicket")
);

// Job opening module
const JobOpeningList = lazy(() =>
  import("../components/JobOpenings/JobOpeningList")
);
const AddJob = lazy(() => import("../components/JobOpenings/AddJob"));
const EditJob = lazy(() => import("../components/JobOpenings/EditJob"));

const ViewJob = lazy(() => import("../components/ViewJob/ViewJob"));

//changes in inventory module add tabpane
const assetTab = lazy(() => import("../components/InventoryTabpane/Asset"));

// employee information management
const EmpInfoManagement = lazy(() =>
  import("../components/EmpInfoManagement/EmpInfoManagement")
);


const PendingTask = lazy(() => import("../components/PendingTask/PendingTask"));

const NTeamRoutes = ({ match }) => {
  let { authUser } = useSelector(({ auth }) => auth);
  let reportingmanager = localStorage.getItem("is_reporting_manager");

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          exact
          path={`${match.url}newattendencelist`}
          component={NewAttendenceRegularizationList}
        />
        <Route
          exact
          path={`${match.url}resignation`}
          component={ResignationFlow}
        />
        <Route
          exact
          path={`${match.url}resignation-userlist`}
          component={UserResignationList}
        />
        <Route
          exact
          path={`${match.url}user-eldi-refer`}
          component={EmpReferListing}
        />
        <Route
          exact
          path={`${match.url}employee-overview`}
          component={EmployeeOverview}
        />

        <Route
          exact
          path={`${match.url}refer-candidate`}
          component={ReferCandidate}
        />
        <Route
          exact
          path={`${match.url}view-refer-candidate/:id`}
          component={ViewReferCandidate}
        />
        <Route exact path={`${match.url}user-assets`} component={UserAssets} />

        <Route exact path="/organization-view" component={OrganizationView} />
        <Route
          exact
          path={`${match.url}goal-reports`}
          component={GoalReports}
        />
        <Route
          exact
          path={`${match.url}newattendence`}
          component={NewAttendenceRegularization}
        />

        <Route
          exact
          path={`${match.url}newattendencelist`}
          component={NewAttendenceRegularizationList}
        />
        <Route
          exact
          path={`${match.url}refer-candidate`}
          component={ReferCandidate}
        />
        <Route
          exact
          path={`${match.url}view-refer-candidate/:id`}
          component={ViewReferCandidate}
        />
        <Route exact path={`${match.url}dashboard`} component={UserDashboard} />

        <Route
          exact
          path={`${match.url}employee-attendence-regularization`}
          component={AttendenceRegularization}
        />
        <Route
          exact
          path={`${match.url}admin-assets-master`}
          component={AdminAssetsMaster}
        />

        <Route exact path={`${match.url}admin-assets`} component={Assets} />

        <Route
          exact
          path={`${match.url}edit-my-goals/:id`}
          component={EditMyGoals}
        />
        <Route exact path={`${match.url}goals`} component={GoalListing} />
        <Route
          exact
          path={`${match.url}goal-records/:goal_id`}
          component={GoalRecords}
        />

        <Route
          exact
          path={`${match.url}goal-reports`}
          component={GoalReports}
        />

        <Route
          exact
          path={`${match.url}admin-assets/add-asset`}
          component={addAsset}
        />

        <Route
          exact
          path={`${match.url}admin-assets/edit-asset/:id`}
          component={editAsset}
        />
        <Route
          exact
          path={`${match.url}team-leave-calender`}
          component={TeamLeaveCalander}
        />

        <Route
          exact
          path={`${match.url}my-profile`}
          component={EmpInfoManagement}
        />
        <Route
          exact
          path={`${match.url}organization-policy`}
          component={OrganizationPolicy}
        />
        <Route exact path={`${match.url}my-in-out`} component={MyInOut} />

        <Route
          exact
          path={`${match.url}newattendence`}
          component={NewAttendenceRegularization}
        />
        <Route
          exact
          path={`${match.url}user-leave-approve`}
          component={LeaveApproval}
        />

        <Route exact path={`${match.url}team-ranking`} component={TeamRank} />
        <Route
          exact
          path={`${match.url}add-team-rank`}
          component={AddTeamRank}
        />

        <Route
          exact
          path={`${match.url}admin-approve`}
          component={AdminLeaveApprove}
        />
        <Route
          exact
          path={`${match.url}leave-application`}
          component={LeaveApplication}
        />

        <Route
          exact
          path={`${match.url}work-from-home-application`}
          component={Wfhlisting}
        />
        <Route
          exact
          path={`${match.url}add-work-from-home-application`}
          component={AddWfhRequest}
        />
        <Route
          exact
          path={`${match.url}work-from-home-approval`}
          component={WfhRequestApprove}
        />
        {/* {isAddLeaveEnabled &&  */}
        <Route
          exact
          path={`${match.url}leave-application/add-leave`}
          component={AddLeave}
        />
        <Route
          exact
          path={`${match.url}resignation`}
          component={ResignationFlow}
        />
        <Route
          exact
          path={`${match.url}resignation-userlist`}
          component={UserResignationList}
        />

        {/* } */}
        <Route
          exact
          path={`${match.url}user-reports`}
          component={Userreports}
        />
        <Route
          exact
          path={`${match.url}team-reports`}
          component={Teamreports}
        />
        <Route
          exact
          path={`${match.url}team-reports/attendence-reports/attendence-register`}
          component={TeamAttendenceReport}
        />
        <Route
          exact
          path={`${match.url}team-reports/attendence-reports/inout-register`}
          component={TeamInoutReport}
        />

        <Route exact path={`${match.url}tickets`} component={ticketListing} />
        <Route
          exact
          path={`${match.url}tickets/add-ticket`}
          component={AddTicket}
        />

        <Route
          exact
          path={`${match.url}tickets/update-ticket/:id`}
          component={EditTicket}
        />
        <Route
          exact
          path={`${match.url}tickets/view-ticket/:id`}
          component={ViewTicket}
        />

        <Route
          exact
          path={`${match.url}manage-tickets/view-ticket/:id`}
          component={ViewTicketTLtickets}
        />

        <Route exact path={`${match.url}team-kpis`} component={TeamKpis} />
        <Route
          exact
          path={`${match.url}view-team-kpi/:id`}
          component={ViewTeamKpi}
        />
        <Route exact path={`${match.url}view-kpi/:id`} component={ViewKpi} />
        <Route
          exact
          path={`${match.url}user-reports/attendence-reports/attendence-register`}
          component={UserAttendenceReport}
        />
        <Route
          exact
          path={`${match.url}user-reports/attendence-reports/inout-register`}
          component={UserInoutReport}
        />
        <Route
          exact
          path={`${match.url}compoff-approve`}
          component={CompOffApprove}
        />

        <Route
          exact
          path={`${match.url}compoff-leave-application`}
          component={CompOffLeaveApplication}
        />
        {/* {isAddCompffEnabled &&  */}
        <Route
          exact
          path={`${match.url}compoff-leave-application/add-compoff-leave`}
          component={CompOffLeave}
        />
        {/* } */}
        <Route
          exact
          path={`${match.url}leave-cancellation`}
          component={LeaveCancellation}
        />

        <Route
          exact
          path={`${match.url}leave-cancellation/:id`}
          component={EditLeaveCancellation}
        />

        <Route
          exact
          path={`${match.url}leave-cancellation-approve`}
          component={LeaveCancellationApproval}
        />
        <Route
          exact
          path={`${match.url}individual-rank`}
          component={QuaterUserRanking}
        />
        <Route
          exact
          path={`${match.url}jobs/openings`}
          component={JobOpeningList}
        />
        <Route exact path={`${match.url}jobs/add`} component={AddJob} />
        <Route
          exact
          path={`${match.url}jobs/edit/:jobId`}
          component={EditJob}
        />
        <Route
          exact
          path={`${match.url}audit-history`} // audit
          component={AuditHistoryOfTL}
        />
        <Route exact path={`${match.url}jobs/view/:id`} component={ViewJob} />
        <Route exact path={`${match.url}inventory`} component={assetTab} />
        <Route
          exact
          path={`${match.url}manage-tickets`}
          component={ticketListingTL}
        />

        {(reportingmanager == "true" ||
          LAST_WORKING_DAY_LIST.includes(authUser?._id) ||
          ONBOARDING_MODULE_ACCESS?.includes(authUser?._id)) && (
          <>
            {!ONBOARDING_MODULE_ACCESS?.includes(authUser?._id) && (
              <>
                <Route
                  exact
                  path={`${match.url}employee-master`}
                  component={EmployeeMasterList}
                />
                <Route
                  exact
                  path={`${match.url}employee-master/update-employee-master/:id`}
                  component={EmpInfoManagement}
                />
                <Route
                  exact
                  path={`${match.url}employee-overview/:id`}
                  component={EmployeeOverview}
                />
                {(reportingmanager == "true" ||
                  authUser?.role_id == NETWORK_TEAM_ROLE_ID ||
                  authUser?.role_id?._id == NETWORK_TEAM_ROLE_ID) && (
                  <>
                    <Route
                      exact
                      path={`${match.url}pending-task-list`}
                      component={PendingTask}
                    />
                    <Route
                      exact
                      path={`${match.url}employee-in-out`}
                      component={EmployeeInout}
                    />
                    <Route
                      exact
                      path="/new-joinees"
                      component={NewJoineesListTL}
                    />
                    <Route
                      exact
                      path={`${match.url}manage-team-regularization`}
                      component={ManageTeamRegularization}
                    />
                    <Route
                      exact
                      path={`${match.url}edit-goals/:id`}
                      component={EditGoals}
                    />
                    <Route
                      exact
                      path={`${match.url}create-goals`}
                      component={AddGoals}
                    />
                    <Route
                      exact
                      path={`${match.url}audit-history-month`} // audit master {temporary routes}
                      component={AuditHistoryMonth}
                    />
                    <Route
                      exact
                      path={`${match.url}audit-month`} // audit
                      component={AuditHistoryMonth}
                    />
                    <Route
                      exact
                      path={`${match.url}team-tickets/view-ticket/:id`}
                      component={ViewTeamTicket}
                    />
                    <Route
                      exact
                      path={`${match.url}team-tickets`}
                      component={ticketListingTLMain}
                    />

                    {(reportingmanager == "true" ||
                      authUser?.role_id == NETWORK_TEAM_ROLE_ID ||
                      authUser?.role_id?._id == NETWORK_TEAM_ROLE_ID) && (
                      <>
                        {reportingmanager == "true" && (
                          <>
                            <Route
                              exact
                              path={`${match.url}resignation-employee-list`}
                              component={ListingForResignation}
                            />
                            <Route
                              exact
                              path={`${match.url}kpis`}
                              component={ManageKpis}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {(ONBOARDING_MODULE_ACCESS?.includes(authUser?._id) ||
              LAST_WORKING_DAY_LIST.includes(authUser?._id)) && (
              <>
                <Route
                  exact
                  path="/doc-approve-list"
                  component={DocumentsApproveList}
                />
                {LAST_WORKING_DAY_LIST.includes(authUser?._id) && (
                  <Route
                    exact
                    path={`${match.url}last-working-day-emps`}
                    component={LWDResign}
                  />
                )}
                <UserSuperModuleRoutes match={match} />
              </>
            )}
          </>
        )}

        {reportingmanager != "true" && (
          <Route exact path={`${match.url}kpis`} component={Kpi} />
        )}

        {authUser?.role_id == NETWORK_TEAM_ROLE_ID ||
          (MANAGE_TICKET_ACCESS.includes(authUser?._id) &&
            !NOT_HAVE_MANAGE_TICKET_ACCESS.includes(authUser?._id) && (
              <Route
                exact
                path={`${match.url}manage-tickets`}
                component={ticketListingTL}
              />
            ))}

        {/* <UserSuperModuleRoutes match={match} /> */}
        <Redirect from="/" to="/dashboard" />
        <Redirect from="*" to="/my-in-out" />
      </Switch>
    </div>
  );
};

export default NTeamRoutes;
