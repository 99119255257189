module.exports = {
  HR_TEAM_ROLE_ID: "6478446f135ec2391f4a8be3", // role_type:hr_team
  ADMIN_OR_HR_ROLE_ID: "60e68a055f40862222c64ec9", //HR Team Elsner role_type:admin
  DIRECTOR_ROLE_ID: "64217927e37dabdb7ba3aeb3", //Chirag
  CEO_ROLE_ID: "642269126c284b24a250500a", //Harshal
  USER_ROLE_ID: "60e68a875f40862222c64ecb",
  INTERN_ROLE_ID: "6580097932cb9d14225b12a8",
  NETWORK_TEAM_ROLE_ID: "646752f697eb2b9b50c6867b",
  ADMIN_ID: "63b6ca9270f2b5b3002cb552",
  CEO_ID: "",
  DIRECTOR_ID: "",
  JOB_MODULE_ACCESS: [
    "64fffa358a2e0d007769e9c8", //Vishal  Patel
    "63b6ca8b70f2b5b3002cb466", //Ajay Sikarwar
    "63b6ca8b70f2b5b3002cb45e", //Nilesh Sahu
    "63b6ca8970f2b5b3002cb44e", //Dipak Patil
    "63b6ca8870f2b5b3002cb44a", //Tarun Bansal
    "63b6ca8870f2b5b3002cb446", //Pankaj Sakariya
    "63b6ca8670f2b5b3002cb433", //Harshal
    "63b6ca8770f2b5b3002cb43e", //Chirag
    "64e2e494e2603d00c6e97efb", //Mansi
    "64a512a7fed85d00290e3a75", //Disha
    "64f17dd897dcb20096115402", //pallav
    "667122ea8328a7003f927970", //gunjan
    "63b6ca9270f2b5b3002cb552", //HR Team Elsner
    "63b6caa170f2b5b3002cb722", //het
    "6583c52e62f944003100aa3b", //Harsiddhi
    "660e3f336fbff2003f2fe1fb", // Harsh Bhatt
  ],
  ONBOARDING_MODULE_ACCESS: [
    "63b6caa570f2b5b3002cb7ae", //preeti
    "63b6caa770f2b5b3002cb7ee", //mini
    "64f17dd897dcb20096115402", //pallav
    "667122ea8328a7003f927970", //gunjan
    "63b6caa070f2b5b3002cb70e", //Prity
    "6583c52e62f944003100aa3b", //Harsiddhi
    "6597c1befc7515034d3cecdd", //Nikita
    "6597b257fc7515034d3ca53c", //Chaitanya
    "65c07852cda0ce003fea1af5", //Sreenath
  ],
  EMPLOYEE_DETAILS_MODULE_ACCESS: [
    "64f17dd897dcb20096115402", //pallav
    "667122ea8328a7003f927970", //gunjan
    "6583c52e62f944003100aa3b", //Harsiddhi
    "6597c1befc7515034d3cecdd", //Nikita
    "6597b257fc7515034d3ca53c", //Chaitanya
    "65c07852cda0ce003fea1af5", //Sreenath
  ],
  NOT_HAVE_MANAGE_TICKET_ACCESS: [
    "644665f196270b01bdddbb9c", //Uttam Nteam
  ],
  ELDI_ADMIN_ACCESS: [
    "652e24972dc3390031df08aa", //Anita
  ],
  BLOCK_NOTIFICATION_HRMS: ["6580097932cb9d14225b12a8"], //Block for intern

  LAST_WORKING_DAY_LIST: [
    //HR
    "63b6ca9270f2b5b3002cb552", //HR Team Elsner
    //Accounts:
    "63b6cab070f2b5b3002cb8fe", //Mr. Biren Shah
    //Admin:
    "63b6caa470f2b5b3002cb786", //Mr. Jeevan Head
    //Networking
    "63b6caa670f2b5b3002cb7ce", //Mr. Maulik Parmar
    "63b6ca9470f2b5b3002cb582", //Mr. Milan Parmar
    "63b6cab770f2b5b3002cb9d2", //Mr. Ketan Ranva
    //Support
    "63b6caa270f2b5b3002cb73e", //Mr. Vishal Mistry
    "63b6ca8770f2b5b3002cb442", //Mr. Nagin Parmar
    "63b6caa470f2b5b3002cb77e", //Mr. Harish Chandra
    "63b6ca9170f2b5b3002cb522", //Mrs. Bansari Shah
    "63b6cab670f2b5b3002cb9ba", //Mrs. Perin Gajjar
    "6400264a527d440037832707", //Mrs. Vanita Kulkarni
    "643e635ca315710082bb1a9f", //Ms. Bansi Dholariya
    "644665f196270b01bdddbb9c", //Mr. Uttam Makwana
    "645cdf4881b233002a6913e2", //Mr. Rahul  Chasiya
    "64a512a7fed85d00290e3a75", //Ms. Disha Mod
    "64e2e494e2603d00c6e97efb", //Ms. Mansi  Dariyanani
    "64f17dd897dcb20096115402", //Mr. Pallav  Ghota
    "667122ea8328a7003f927970", // gunjan
    "64f6bb568826a60031a403cf", //Mr. Ketankumar Vyas
    "65681f326973ba0031bf3b2b", //Mr. Hitesh Suthar
    "65c07852cda0ce003fea1af5", //Mr. Sreenath Ezhava

    // copy of job acces
    "63b6ca8670f2b5b3002cb433", //Harshal
    "63b6ca8770f2b5b3002cb43e", //Chirag
    "64e2e494e2603d00c6e97efb", //Mansi
    "6597c1befc7515034d3cecdd", //Nikita
    "6597b257fc7515034d3ca53c", //Chaitanya
    "6583c52e62f944003100aa3b", //Harsiddhi
  ],

  ACCOUNTS_TEAM: [
    "660a38c0768eaa003f5727c8", // dhaval soni
    "65681f326973ba0031bf3b2b", //hitesh suthar
  ],

  RESIGNATION_TL_BUTTON: [
    "63b6ca8e70f2b5b3002cb4d2", //Mr. Hiren Rathod
    "63b6ca9270f2b5b3002cb546", //Mr. Ahmad Palsaniya
    "64f1667b97dcb2009610a547", //Ms. Aashita  Gehlot
    "64fffa358a2e0d007769e9c8", //Mr. Vishal  Patel
    "63b6caa170f2b5b3002cb722", //Mr. Het Balar
    "63b6ca8b70f2b5b3002cb46a", //Mr. Aakif Kadiwala
    "63b6ca8f70f2b5b3002cb4de", //Mr. Varun Rajyaguru
    "63b6caaa70f2b5b3002cb842", //Mr. Dhaval Babariya
    "63edde5865deba00292a9146", //Mrs. Priya Puri
    "63b6ca8b70f2b5b3002cb466", //Mr. Ajay Sikarwar
    "63b6ca8870f2b5b3002cb446", //Mr. Pankaj Sakariya
    "64534a6a1b26c100384d1290", //Mr. Alpesh  Yadav
    "64a512a7fed85d00290e3a75", //Ms. Disha Mod
    "63b6ca8a70f2b5b3002cb45a", //Mr. Manoj Mondal
    "63b6cab370f2b5b3002cb956", //Mr. Ishan Rao
    "63b6ca8970f2b5b3002cb44e", //Mr. Dipak Patil
    "63ee070965deba00292a91e5", //Ms. Yogita Jain
    "63b6ca9770f2b5b3002cb5de", //Ms. Sapna Rathod
    "63b6ca8e70f2b5b3002cb4c2", //Mr. Shahnavaz Kureshi
    "650a9782e4b54101f75d06ff", //Mr. Hitesh Asnani
    "63b6ca8870f2b5b3002cb44a", //Mr. Tarun Bansal
    "63b6caa970f2b5b3002cb836", //Ms. Pallavi Saini
    "652384f1f4ebdc056daa53b0", //Mrs. Shruti  Sharma
    "63b6ca8970f2b5b3002cb452", //Mr. Santosh Dhaduk
    "63b6cab670f2b5b3002cb9ba", //Mrs. Perin Gajjar
    "63b6ca8b70f2b5b3002cb45e", //Mr. Nilesh Sahu
    "6461cd08504677006f34f4e4", //Mr. Farhan  Mansuri
    "65681f326973ba0031bf3b2b", //Mr. Hitesh Suthar
    "63b6ca9170f2b5b3002cb526", //Ms. Khyati Jethva
    "63b6caa270f2b5b3002cb73e", //Mr. Vishal Mistry
    "642bfaca5e1043002ac353d2", //Mr. Tirth Jani
    "63b6cab770f2b5b3002cb9ce", //Ms. Ishita Makvana
    "656eca80b6de7e00318e1ea7", //Mr. Mohd Ubbad  Mansuri
  ],

  MANAGE_TICKET_ACCESS: [
    "63b6ca8770f2b5b3002cb442", //Nagin
    "63b6caa270f2b5b3002cb73e", //Vishal Nteam
    "63b6cab670f2b5b3002cb9ba", //Perin
    "63b6cab370f2b5b3002cb942", //Rajvi
    "63b6ca9270f2b5b3002cb552", // HR Team Elsner
  ],
  USER_EMP_MASTER_ACCESS_VIEW: [
    "65681f326973ba0031bf3b2b", //Hitesh Suthar
  ],
  EDIT_BUTTON_LWD: [
    "63b6ca9270f2b5b3002cb552", //HR team / admin
    "64a512a7fed85d00290e3a75", //disha
  ],
};
