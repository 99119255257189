import React, { lazy, useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import ListingForResignation from "../components/Listing/ListingForResignation";
import LWDResign from "../components/Listing/LWDResign";

const EmployeeOverview = lazy(() =>
  import("../components/EmpInfoManagement/EmployeeOverview")
);
const ReferEldiCandidate = lazy(() =>
  import("../components/Eldi/ReferEldiCandidate")
);
const AuditHistoryMonth = lazy(() =>
  import("../components/Audit/SuperAdminAuditHistory/AuditHistoryMonth")
);
const AuditHistoryOfEmp = lazy(() =>
  import("../components/Audit/AuditHistory/AuditHistoryOfEmp")
);
const SopMaster = lazy(() => import("../components/Audit/SopMaster/SopMaster"));
const Audits = lazy(() => import("../components/Audit/AuditHistory/Audits"));
const AuditMaster = lazy(() => import("../components/Audit/AuditMaster"));
const ViewReferCandidate = lazy(() =>
  import("../components/ReferCandidate/ViewReferCandidate")
);
const AppSettings = lazy(() => import("../components/AppSettings/AppSettings"));
const CandidateApproveReject = lazy(() =>
  import("../components/Onboarding/CandidateApproveReject")
);
const DocumentsApproveList = lazy(() =>
  import("../components/Onboarding/DocumentsApproveList")
);
const OnboardingInitiation = lazy(() =>
  import("../components/Onboarding/OnboardingInitiation")
);
const NewJoineesListAdmin = lazy(() =>
  import("../components/NewJoinees/NewJoineesListAdmin")
);
const NewJoineesForm = lazy(() =>
  import("../components/NewJoinees/NewJoineesForm")
);
const OrganizationView = lazy(() =>
  import("../components/OrganizationView/OrganizationView")
);
const NewAttendenceRegularizationList = lazy(() =>
  import(
    "../components/New-Attendence-regularization/NewAttendenceRegularizationList"
  )
);
const NewAttendenceRegularization = lazy(() =>
  import(
    "../components/New-Attendence-regularization/NewAttendenceRegularization"
  )
);
const EditMyGoals = lazy(() => import("../components/Goals/EditMyGoals"));
const EditGoals = lazy(() => import("../components/Goals/EditGoals"));
const AdminOnduty = lazy(() => import("../components/AdminOnduty/AdminOnduty"));
const Viewuserkpi = lazy(() => import("../components/Userkpi/Viewuserkpi"));
const Userkpi = lazy(() => import("../components/Userkpi/Userkpi"));
const Viewadminkpi = lazy(() => import("../components/Adminkpi/Viewadminkpi"));
const LeaveApproval = lazy(() =>
  import("../components/leave/addleave/LeaveApproval")
);
const LeaveApplication = lazy(() =>
  import("../components/leave/addleave/LeaveApplication")
);
const CompOffLeave = lazy(() =>
  import("../components/leave/addleave/CompOffLeave")
);
const AddLeave = lazy(() => import("../components/leave/addleave/AddLeave"));
const QuaterUserRanking = lazy(() =>
  import("../components/IndividualquaterlyRank/QuaterUserRanking")
);
const UploadDocsCandidate = lazy(() =>
  import("../components/Onboarding/UploadDocsCandidate")
);
const NewJoineesListTL = lazy(() =>
  import("../components/NewJoinees/NewJoineesListTL")
);
const LeaveCancellation = lazy(() =>
  import("../components/leave/addleave/LeaveCancellation")
);
const EditLeaveCancellation = lazy(() =>
  import("../components/leave/addleave/EditLeaveCancellation")
);
const CompOffApprove = lazy(() =>
  import("../components/leave/addleave/CompOffApprove")
);
const AdminLeaveApprove = lazy(() =>
  import("../components/leave/addleave/AdminLeaveApprove")
);

const AuditHistoryOfTL = lazy(() =>
  import("../components/Audit/AuditHistory/AuditHistoryOfTL")
);
const EmpReferListing = lazy(() =>
  import("../components/Eldi/EmpReferListing")
);
const UserProfile = lazy(() => import("../components/UserProfile/UserProfile"));

const Wfhlisting = lazy(() =>
  import("../components/leave/addleave/Wfhlisting")
);
const AddWfhRequest = lazy(() =>
  import("../components/leave/addleave/AddWfhRequest")
);
const AdminWfhRequestApprove = lazy(() =>
  import("../components/leave/addleave/AdminWfhRequestApprove")
);
const CEORegularization = lazy(() =>
  import("../components/CEORegurlarization/CEORegurlarization")
);

const WfhRequestApprove = lazy(() =>
  import("../components/leave/addleave/WfhRequestApprove")
);

const CeoCompOffApprove = lazy(() =>
  import("../components/CeoCompoffApproval/CeoCompoffApproval")
);

const DirectorCompOffApprove = lazy(() =>
  import("../components/DirectorCompoffApproval/DirectorCompoffApproval")
);

const AdminLeaveApproval = lazy(() =>
  import("../components/leave/addleave/AdminLeaveApprovel")
);

const AdminDepartment = lazy(() =>
  import("../components/AdminDepartment/AdminDepartment")
);

const AdminDesignation = lazy(() =>
  import("../components/AdminDepartment/AdminDesignation")
);

const Holidays = lazy(() => import("../components/Holidays/ViewHolidays"));

const Employeesrank = lazy(() =>
  import("../components/EmployeeRank/EmployeeRank")
);

const AddTeamRank = lazy(() => import("../components/AddTeamRank/AddTeamRank"));

const TeamRank = lazy(() => import("../components/TeamRank/TeamRank"));

const OrganizationPolicy = lazy(() =>
  import("../components/OrganizationPolicy/OrganizationPolicy")
);

const Employeeleavebalance = lazy(() =>
  import("../components/Employeeleavebalance/Employeeleavebalance")
);

const AdminLeaveMaster = lazy(() =>
  import("../components/leave/addleave/AdminLeaveMaster")
);
const UserDashboard = lazy(() =>
  import("../components/UserDashboard/UserDashboard")
);

const EmployeeMasterList = lazy(() =>
  import("../components/EmployeeMasterList/EmployeeMasterList")
);
const EmployeeInout = lazy(() =>
  import("../components/EmployeeInout/EmployeeInout")
);

const AdminRegularization = lazy(() =>
  import("../components/AdminRegurlarization/AdminRegurlarization")
);
const DirectorRegularization = lazy(() =>
  import("../components/DirectorRegularization/DirectorRegularization")
);
const AdminCompOffApprove = lazy(() =>
  import("../components/leave/addleave/AdminCompOffApprove")
);
const AdminLeaveCancellation = lazy(() =>
  import("../components/leave/addleave/AdminLeaveCancellation")
);

const AdminDashboard = lazy(() =>
  import("../components/AdminDashboard/AdminDashboard")
);
const LeaveCancellationApproval = lazy(() =>
  import("../components/leave/addleave/LeaveCancellationApproval")
);
const Exemployee = lazy(() =>
  import("../components/ExemployeeList/ExemployeeList")
);
const AddResignation = lazy(() =>
  import("../components/AddResignation/AddResignation")
);
const EditResignation = lazy(() =>
  import("../components/EditResignation/EditResignation.")
);

const MyInOut = lazy(() => import("../components/MyInOut/MyInOut"));
const AttendenceRegularization = lazy(() =>
  import("../components/AttendenceRegularization/AttendenceRegularization")
);
const ManageTeamRegularization = lazy(() =>
  import("../components/ManageTeamRegularization/ManageTeamRegularization")
);
const AddEmployeeMaster = lazy(() =>
  import("../components/AddEmployeeMaster/AddEmployeeMaster")
);
const AddInventoryMaster = lazy(() =>
  import("../components/AddInventoryMaster/AddInventoryMaster")
);
const EditInventoryMaster = lazy(() =>
  import("../components/EditInventoryMaster/EditInventoryMaster")
);
const EditEmployeeMaster = lazy(() =>
  import("../components/EditEmployeeMaster/EditEmployeeMaster")
);

const AddEmployeeRank = lazy(() =>
  import("../components/AddEmployeeRank/AddEmployeeRank")
);
//Admin-Reports

const Adminreports = lazy(() => import("../components/Reports/Reports"));
const Userreports = lazy(() => import("../components/UserReports/UserReport"));
const Teamreports = lazy(() => import("../components/TeamReports/UserReport"));

const ReferCandidate = lazy(() =>
  import("../components/ReferCandidate/ReferCandidate")
);
const EmpLeftreport = lazy(() =>
  import("../components/Reports/Employee Report/EmployeeLeftReport")
);
const EmpJoiningreport = lazy(() =>
  import("../components/Reports/Employee Report/EmployeeJoiningReport")
);
const EmpDocreport = lazy(() =>
  import("../components/Reports/Employee Report/EmployeeDocAttendence")
);

const AttendenceReport = lazy(() =>
  import("../components/Reports/Attendence Report/AttendenceRegister")
);
const Attendenceregularization = lazy(() =>
  import("../components/Reports/Attendence Report/Attendenceregularization")
);
const InoutReport = lazy(() =>
  import("../components/Reports/Attendence Report/InOutRegister")
);
const MonthlyReport = lazy(() =>
  import("../components/Reports/Attendence Report/MonthlyReport")
);
const UserAttendenceReport = lazy(() =>
  import("../components/UserReports/AttendenceReport/UserAttendenceRegister")
);
const UserInoutReport = lazy(() =>
  import("../components/UserReports/AttendenceReport/UserInOutRegister")
);

const TeamAttendenceReport = lazy(() =>
  import("../components/TeamReports/AttendenceReport/UserAttendenceRegister")
);
const TeamInoutReport = lazy(() =>
  import("../components/TeamReports/AttendenceReport/UserInOutRegister")
);

const Adminkpi = lazy(() => import("../components/Adminkpi/Adminkpi"));

const LeaveApplicationReport = lazy(() =>
  import("../components/Reports/Leave Report/LeaveApplication")
);
const LeaveApprovalReport = lazy(() =>
  import("../components/Reports/Leave Report/LeaveApproval")
);

const TeamLeaveCalander = lazy(() =>
  import("../components/TeamLeaveCalander/TeamLeaveCalander")
);
const LeaveBalanceReport = lazy(() =>
  import("../components/Reports/Leave Report/LeaveBalance")
);
const LeaveCompoffReport = lazy(() =>
  import("../components/Reports/Leave Report/CompOffList")
);
const AppoinmentLetter = lazy(() =>
  import("../components/Reports/Letter/AppoinmentLetter")
);
const ConfirmationLetter = lazy(() =>
  import("../components/Reports/Letter/ConfirmationLetter")
);
const AdminTickets = lazy(() => import("../components/Ticket/AdminTickets"));

const EmployeeMasterView = lazy(() =>
  import("../components/EmployeeMasterView/EmployeeMasterView")
);

const viewexemployee = lazy(() =>
  import("../components/viewexemployee/ViewExEmpTab")
);
const ResignationView = lazy(() =>
  import("../components/ResignationView/ResignationView")
);

//leave module
const AdminAddLeave = lazy(() =>
  import("../components/leave/addleave/AdminAddLeave")
);

const AdminAddCompoffApprove = lazy(() =>
  import("../components/leave/addleave/AdminAddCompoffApprove")
);
const CompOffLeaveApplication = lazy(() =>
  import("../components/leave/addleave/CompOffLeaveApplication")
);

const achivementsListing = lazy(() =>
  import("../components/Achivements/achivementList")
);
const addAchivement = lazy(() =>
  import("../components/Achivements/AddAchivement")
);
const editAchivement = lazy(() =>
  import("../components/Achivements/EditAchivement")
);

const policiesListing = lazy(() =>
  import("../components/policies/policiesList")
);
const addPolicy = lazy(() => import("../components/policies/addPolicy"));
// const editPolicy = lazy(() => import("../components/policies/editPolicy"));

//Ticket Module
const teamTicketAdmin = lazy(() =>
  import("../components/TeamTicketAdmin/TicketList")
);

const TeamKpis = lazy(() => import("../components/TeamKpis/TeamKpis"));

const ManageKpis = lazy(() => import("../components/ManageKPI/ManageKpi"));

const ticketListingTL = lazy(() =>
  import("../components/TeamTicket/TicketList")
);

const ticketListingTLMain = lazy(() =>
  import("../components/TeamTicketMaintl/TicketList")
);

const ViewTicketTLtickets = lazy(() =>
  import("../components/ViewTicketTL/ViewTicketTL")
);

//inventory module

const AdminAssetsMaster = lazy(() =>
  import("../components/Inventory/AdminAssetsMaster")
);

const Assets = lazy(() => import("../components/Inventory/Assets"));

const UserAssets = lazy(() => import("../components/UserInventory/Assets"));

const addAsset = lazy(() =>
  import("../components/InventoryTabpane/AddInventoryMaster")
);

const editAsset = lazy(() =>
  import("../components/Inventory/EditInventoryMaster")
);
const Kpi = lazy(() => import("../components/Kpi/Kpi"));

const TestKpi = lazy(() => import("../components/TestKpi/TestKpi"));

const ViewKpi = lazy(() => import("../components/Kpi/ViewKpi"));

const ViewTeamKpi = lazy(() => import("../components/TeamKpis/ViewTeamKpi"));

const ticketListing = lazy(() => import("../components/Ticket/TicketList"));

const AddTicket = lazy(() => import("../components/Ticket/AddTicket"));

const FeedbackForm = lazy(() => import("../components/Feedback/FeedbackForm"));

const EditTicket = lazy(() => import("../components/EditTicket/EditTicket"));

const ViewTicket = lazy(() => import("../components/ViewTicket/ViewTicket"));
const AddGoals = lazy(() => import("../components/Goals/AddGoals"));
const GoalListing = lazy(() => import("../components/Goals/GoalListing"));
const GoalRecords = lazy(() => import("../components/Goals/GoalRecords"));
const SubGoals = lazy(() => import("../components/Goals/SubGoals/SubGoals"));
const NewSubGoal = lazy(() =>
  import("../components/Goals/SubGoals/NewSubGoal")
);
const EditSubGoal = lazy(() =>
  import("../components/Goals/SubGoals/EditSubGoals")
);

// assigned sub goals
const MySubGoalsList = lazy(() =>
  import("../components/Goals/MySubGoalsList/MySubGoalsList")
);

const GoalReports = lazy(() => import("../components/GoalReports/GoalReports"));

const ViewUserTicket = lazy(() =>
  import("../components/ViewTicket/viewTicketAsAdmin")
);

const ViewTeamTicket = lazy(() =>
  import("../components/ViewTicket/ViewTeamTicket")
);

const editPolicy = lazy(() => import("../components/policies/editPolicy"));

// Job opening module
const JobOpeningList = lazy(() =>
  import("../components/JobOpenings/JobOpeningList")
);
const AddJob = lazy(() => import("../components/JobOpenings/AddJob"));
const EditJob = lazy(() => import("../components/JobOpenings/EditJob"));

const ViewJob = lazy(() => import("../components/ViewJob/ViewJob"));

//changes in inventory module add tabpane
const assetTab = lazy(() => import("../components/InventoryTabpane/Asset"));

// employee information management
const EmpInfoManagement = lazy(() =>
  import("../components/EmpInfoManagement/EmpInfoManagement")
);

// Audit Module Routes

const NewAudit = lazy(() =>
  import("../components/Audit/AuditHistory/NewAudit")
);
const AuditHistoryView = lazy(() =>
  import("../components/Audit/AuditHistory/AuditHistoryView")
);
const AuditPerformance = lazy(() =>
  import("../components/Audit/AuditPerformance")
);
const ViewAudits = lazy(() =>
  import("../components/Audit/SuperAdminAuditHistory/ViewAudits")
);
const SuperAdminAuditHistory = lazy(() =>
  import("../components/Audit/SuperAdminAuditHistory/SuperAdminAuditHistory")
);
const AuditReports = lazy(() => import("../components/Audit/AuditReports"));
const EditAudit = lazy(() =>
  import("../components/Audit/AuditHistory/EditAudit")
);

const PendingTask = lazy(() => import("../components/PendingTask/PendingTask"));



const DirectorRoutes = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          exact
          path={`${match.url}employee-overview/:id`}
          component={EmployeeOverview}
        />
        <Route
            exact
            path={`${match.url}last-working-day-emps`}
            component={LWDResign}
          />
        <Route
          exact
          path={`${match.url}resignation-employee-list`}
          component={ListingForResignation}
        />
        <Route
          exact
          path={`${match.url}employee-overview`}
          component={EmployeeOverview}
        />
        <Route
          exact
          path={`${match.url}attendence-reports/attendence-regularization`}
          component={Attendenceregularization}
        />
        <Route exact path={`${match.url}inventory`} component={assetTab} />
        <Route
          exact
          path={`${match.url}refer-candidate`}
          component={ReferCandidate}
        />
        <Route
          exact
          path={`${match.url}individual-rank`}
          component={QuaterUserRanking}
        />
        <Route
          exact
          path={`${match.url}view-refer-candidate/:id`}
          component={ViewReferCandidate}
        />
        <Route
          exact
          path={`${match.url}newattendence`}
          component={NewAttendenceRegularization}
        />
        <Route
          exact
          path={`${match.url}newattendencelist`}
          component={NewAttendenceRegularizationList}
        />
        <Route exact path={`${match.url}adminonduty`} component={AdminOnduty} />
        <Route exact path={`${match.url}kpis`} component={ManageKpis} />
        <Route exact path={`${match.url}team-kpis`} component={TeamKpis} />
        <Route
          exact
          path={`${match.url}view-team-kpi/:id`}
          component={ViewTeamKpi}
        />
        <Route exact path={`${match.url}view-kpi/:id`} component={ViewKpi} />
        <Route
          exact
          path={`${match.url}dashboard`}
          component={AdminDashboard}
        />
        <Route
          exact
          path={`${match.url}team-leave-calender`}
          component={TeamLeaveCalander}
        />
        <Route
          exact
          path={`${match.url}my-profile`}
          component={EmpInfoManagement}
        />
        <Route
          exact
          path={`${match.url}organization-policy`}
          component={OrganizationPolicy}
        />
        <Route exact path={`${match.url}my-in-out`} component={MyInOut} />
        <Route
          exact
          path={`${match.url}employee-master`}
          component={EmployeeMasterList}
        />
        <Route
          exact
          path={`${match.url}employee-master/add-employee-master`}
          component={AddEmployeeMaster}
        />
        <Route
          exact
          path={`${match.url}employee-master/update-employee-master/:id`}
          component={EmpInfoManagement}
        />
        <Route
          exact
          path={`${match.url}employee-master/view-employee-master/:id`}
          component={EmployeeMasterView}
        />
        <Route exact path={`${match.url}ex-employee`} component={Exemployee} />
        <Route
          exact
          path={`${match.url}ex-employee/add-resignation`}
          component={AddResignation}
        />
        <Route
          exact
          path={`${match.url}ex-employee/update-resignation/:id`}
          component={EditResignation}
        />
        <Route
          exact
          path={`${match.url}ex-employee/view-resignation/:id`}
          component={viewexemployee}
        />
        <Route
          exact
          path={`${match.url}employee-in-out`}
          component={EmployeeInout}
        />
        <Route
          exact
          path={`${match.url}employee-ranking`}
          component={Employeesrank}
        />
        <Route
          exact
          path={`${match.url}admin-department`}
          component={AdminDepartment}
        />
        <Route
          exact
          path={`${match.url}admin-designation`}
          component={AdminDesignation}
        />
        <Route exact path={`${match.url}holidays`} component={Holidays} />
        <Route
          exact
          path={`${match.url}policies`}
          component={policiesListing}
        />
        <Route
          exact
          path={`${match.url}policies/add-policy`}
          component={addPolicy}
        />
        <Route
          exact
          path={`${match.url}policies/edit-policy/:id`}
          component={editPolicy}
        />
        <Route
          exact
          path={`${match.url}employee-leave-balance`}
          component={Employeeleavebalance}
        />
        <Route
          exact
          path={`${match.url}achivements`}
          component={achivementsListing}
        />
        <Route
          exact
          path={`${match.url}manage-team-regularization`}
          component={ManageTeamRegularization}
        />
        <Route
          exact
          path={`${match.url}user-leave-approve`}
          component={LeaveApproval}
        />
        <Route exact path={`${match.url}team-ranking`} component={TeamRank} />
        <Route
          exact
          path={`${match.url}add-employee-rank`}
          component={AddEmployeeRank}
        />
        <Route
          exact
          path={`${match.url}add-team-rank`}
          component={AddTeamRank}
        />
        <Route
          exact
          path={`${match.url}admin-approve`}
          component={AdminLeaveApprove}
        />
        <Route
          exact
          path={`${match.url}leave-application`}
          component={LeaveApplication}
        />
        <Route
          exact
          path={`${match.url}work-from-home-application`}
          component={Wfhlisting}
        />
        <Route
          exact
          path={`${match.url}add-work-from-home-application`}
          component={AddWfhRequest}
        />
        <Route
          exact
          path={`${match.url}work-from-home-approval`}
          component={WfhRequestApprove}
        />
        {/* {isAddLeaveEnabled &&  */}
        <Route
          exact
          path={`${match.url}leave-application/add-leave`}
          component={AddLeave}
        />
        {/* } */}
        <Route
          exact
          path={`${match.url}admin-reports`}
          component={Adminreports}
        />
        <Route
          exact
          path={`${match.url}employee-reports/emp-left-report`}
          component={EmpLeftreport}
        />
        <Route
          exact
          path={`${match.url}employee-reports/emp-joining-report`}
          component={EmpJoiningreport}
        />{" "}
        <Route
          exact
          path={`${match.url}employee-reports/emp-doc-report`}
          component={EmpDocreport}
        />{" "}
        <Route
          exact
          path={`${match.url}attendence-reports/attendence-register`}
          component={AttendenceReport}
        />
        <Route
          exact
          path={`${match.url}attendence-reports/inout-register`}
          component={InoutReport}
        />
        <Route
          exact
          path={`${match.url}leave-reports/`}
          component={LeaveApplicationReport}
        />
        <Route
          exact
          path={`${match.url}leave-reports/leave-approval`}
          component={LeaveApprovalReport}
        />
        <Route
          exact
          path={`${match.url}leave-reports/leave-balance`}
          component={LeaveBalanceReport}
        />
        <Route
          exact
          path={`${match.url}leave-reports/comp-off-leave`}
          component={LeaveCompoffReport}
        />
        <Route exact path={`${match.url}tickets`} component={ticketListing} />
        <Route
          exact
          path={`${match.url}tickets/add-ticket`}
          component={AddTicket}
        />
        <Route
          exact
          path={`${match.url}tickets/update-ticket/:id`}
          component={EditTicket}
        />
        <Route
          exact
          path={`${match.url}tickets/view-ticket/:id`}
          component={ViewTicket}
        />
        <Route
          exact
          path={`${match.url}employee-tickets/view-ticket/:id`}
          component={ViewUserTicket}
        />
        <Route
          exact
          path={`${match.url}admin-reports`}
          component={Adminreports}
        />
        <Route
          exact
          path={`${match.url}employee-reports/emp-left-report`}
          component={EmpLeftreport}
        />
        <Route
          exact
          path={`${match.url}employee-reports/emp-joining-report`}
          component={EmpJoiningreport}
        />{" "}
        <Route
          exact
          path={`${match.url}employee-reports/emp-doc-report`}
          component={EmpDocreport}
        />{" "}
        <Route
          exact
          path={`${match.url}attendence-reports/attendence-register`}
          component={AttendenceReport}
        />
        <Route
          exact
          path={`${match.url}attendence-reports/inout-register`}
          component={InoutReport}
        />
        <Route
          exact
          path={`${match.url}leave-reports/leave-application`}
          component={LeaveApplicationReport}
        />
        <Route
          exact
          path={`${match.url}leave-reports/leave-approval`}
          component={LeaveApprovalReport}
        />
        <Route
          exact
          path={`${match.url}leave-reports/leave-balance`}
          component={LeaveBalanceReport}
        />
        <Route
          exact
          path={`${match.url}leave-reports/comp-off-leave`}
          component={LeaveCompoffReport}
        />
        <Route exact path={`${match.url}tickets`} component={ticketListing} />
        <Route
          exact
          path={`${match.url}tickets/add-ticket`}
          component={AddTicket}
        />
        <Route
          exact
          path={`${match.url}tickets/update-ticket/:id`}
          component={EditTicket}
        />
        <Route
          exact
          path={`${match.url}tickets/view-ticket/:id`}
          component={ViewTicket}
        />
        <Route
          exact
          path={`${match.url}team-tickets`}
          component={ticketListingTLMain}
        />
        <Route
          exact
          path={`${match.url}manage-tickets`}
          component={ticketListingTL}
        />
        <Route
          exact
          path={`${match.url}manage-tickets/view-ticket/:id`}
          component={ViewTicketTLtickets}
        />
        <Route
          exact
          path={`${match.url}employee-tickets`}
          component={teamTicketAdmin}
        />
        <Route
          exact
          path={`${match.url}team-tickets/view-ticket/:id`}
          component={ViewTeamTicket}
        />
        <Route
          exact
          path={`${match.url}attendence-reports/attendence-register`}
          component={UserAttendenceReport}
        />
        <Route
          exact
          path={`${match.url}attendence-reports/inout-register`}
          component={UserInoutReport}
        />
        {/* <Route
  exact
  path={`${match.url}admin-approve/add-admin-leave-approve`}
  component={AddAdminLeaveApprove}
/> */}
        {/* <Route
  exact
  path={`${match.url}leave-application/compoff-leave`}
  component={CompOffLeaveList}
/> */}
        {/* <Route
  exact
  path={`${match.url}leave-master`}
  component={LeaveMaster}
/> */}
        <Route
          exact
          path={`${match.url}compoff-approve`}
          component={CompOffApprove}
        />
        <Route
          exact
          path={`${match.url}compoff-leave-application`}
          component={CompOffLeaveApplication}
        />
        {/* {isAddCompffEnabled && */}
        <Route
          exact
          path={`${match.url}compoff-leave-application/add-compoff-leave`}
          component={CompOffLeave}
        />
        {/* } */}
        <Route
          exact
          path={`${match.url}leave-cancellation`}
          component={LeaveCancellation}
        />
        <Route
          exact
          path={`${match.url}leave-cancellation/:id`}
          component={EditLeaveCancellation}
        />
        <Route
          exact
          path={`${match.url}leave-cancellation-approve`}
          component={LeaveCancellationApproval}
        />
        <Route
          exact
          path={`${match.url}director-regularizatrion`}
          component={DirectorRegularization}
        />
        <Route
          exact
          path={`${match.url}director-compoff-approval`}
          component={DirectorCompOffApprove}
        />
        <Route
          exact
          path={`${match.url}admin-assets-master`}
          component={AdminAssetsMaster}
        />
        <Route exact path={`${match.url}admin-assets`} component={Assets} />
        <Route
          exact
          path={`${match.url}admin-assets/add-asset`}
          component={addAsset}
        />
        <Route
          exact
          path={`${match.url}admin-assets/edit-asset/:id`}
          component={editAsset}
        />
        <Route exact path={`${match.url}user-assets`} component={UserAssets} />
        <Route
          exact
          path={`${match.url}jobs/openings`}
          component={JobOpeningList}
        />
        <Route exact path={`${match.url}jobs/add`} component={AddJob} />
        <Route
          exact
          path={`${match.url}jobs/edit/:jobId`}
          component={EditJob}
        />
        <Route exact path={`${match.url}jobs/view/:id`} component={ViewJob} />
        <Route exact path={`${match.url}create-goals`} component={AddGoals} />
        <Route
          exact
          path={`${match.url}edit-my-goals/:id`}
          component={EditMyGoals}
        />
        <Route
          exact
          path={`${match.url}edit-goals/:id`}
          component={EditGoals}
        />
        <Route exact path={`${match.url}goals`} component={GoalListing} />
        {/* <Route
      exact
      path={`${match.url}goal-records/:goal_id`}
      component={GoalRecords}
    /> */}
        {/* <Route
      exact
      path={`${match.url}sub-goals/:id`}
      component={SubGoals}
    /> */}
        {/* <Route
      exact
      path={`${match.url}create-subgoals/:id`}
      component={NewSubGoal}
    /> */}
        {/* <Route
      exact
      path={`${match.url}edit-subgoals/:id`}
      component={EditSubGoal}
    /> */}
        {/* <Route
      exact
      path={`${match.url}my-subgoals`}
      component={MySubGoalsList}
    /> */}
        <Route
          exact
          path={`${match.url}goal-reports`}
          component={GoalReports}
        />
        <Route exact path="/organization-view" component={OrganizationView} />
        <Route
          exact
          path={`${match.url}pending-task-list`}
          component={PendingTask}
        />
        {/* audit module routes for Director */}
        <Route
          exact
          path={`${match.url}auditmaster`} // audit master {temporary routes}
          component={AuditMaster}
        />
        <Route
          exact
          path={`${match.url}audit-history/:employeeId`} // audit master {temporary routes}
          component={AuditHistoryOfEmp}
        />
        <Route
          exact
          path={`${match.url}sopmaster/:departmentId`} // audit master {temporary routes}
          component={SopMaster}
        />
        {/* Audits */}
        <Route
          exact
          path={`${match.url}audits`} // audit master {temporary routes}
          component={Audits}
        />{" "}
        {/* Audit History */}
        <Route
          exact
          path={`${match.url}audit-history`}
          // path={`${match.url}audit-history/:employeeId`}              // audit master {temporary routes}
          component={AuditHistoryOfEmp}
        />
        <Route
          exact
          path={`${match.url}audit-history-month`} // audit master {temporary routes}
          component={AuditHistoryView}
        />
        {/* <Route
      exact
      path={`${match.url}new-audit/:employeeId`}              // audit master {temporary routes}
      component={NewAudit}
    /> */}
        {/* <Route
      exact
      path={`${match.url}auditreports`}              // audit master {temporary routes}
      component={AuditReports}
    /> */}
        <Route
          exact
          path={`${match.url}auditperformance`} // audit master {temporary routes}
          component={AuditPerformance}
        />
        <Route
          exact
          path={`${match.url}viewaudits`} // superadmin {temporary routes}
          component={ViewAudits}
        />
        <Route
          exact
          path={`${match.url}audithistory/:employeeId`} // superadmin {temporary routes}
          component={SuperAdminAuditHistory}
        />
        <Route
          exact
          path={`${match.url}audit-month`} // superadmin {temporary routes}
          component={AuditHistoryMonth}
        />
        <Redirect from="/" to="/dashboard" />
        <Redirect from="*" to="/my-in-out" />
        {/* <Route
        exact
        path="/organization-view"
        component={OrganizationView}
      /> */}
      </Switch>
    </div>
  );
};

export default DirectorRoutes;
