import React, { useEffect, useState } from 'react';
import SignDocs from './SignDocs';
import { Button } from 'antd';
import ElsnerLogo from '../../assets/images/Logowhite.svg'
import { footerText } from "util/config";
import { Card } from "antd";
import Service from '../../service';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";

const SignedDocCandidate = () => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    let token = query.get("token");

    const dispatch = useDispatch();

    const [empDocs, setEmpDocs] = useState([])
    const [currentDoc, setCurrentDoc] = useState({ url: '' })
    const [allDocsSigned, setAllDocsSigned] = useState(false);
    const [alreadySignMsg, setAlreadySignMsg] = useState("All documents are submitted");
    const [filename, setFilename] = useState(["NDA", "Security Cheque", "Cheque Declaration", "Tenure Commitment Agreement"]);


    useEffect(() => {
        getOnboardingEmpDocs()
    }, [])


    useEffect(() => {
        const firstUnsignedOrUnapprovedDoc = filename.find(name => {
            const doc = empDocs?.find(item => item.doc_Type === name);
            return doc && (!doc.isSigned);  // Updated this line
        });
        if (firstUnsignedOrUnapprovedDoc) {
            const doc = empDocs?.find(item => item.doc_Type === firstUnsignedOrUnapprovedDoc);
            setCurrentDoc(doc);
            setAllDocsSigned(false); // Not all docs are signed or approved
        } else  {
            setAllDocsSigned(true); // All docs are signed and approved
        }
    }, [empDocs, filename]);

    const getOnboardingEmpDocs = async values => {
        try {
            dispatch(showAuthLoader());
            const response = await Service.makeAPICall({
                methodName: Service.getMethod,
                api_url: `${Service.getOnboardingEmpDetailsByToken}/${token}`,
            });

            if (response.data && response.data.data) {
                console.log("---",response.data,"\n========",response.data.data)
                let newFilename = [];
                if(response?.data?.data?.isCheque == "true"){
                    newFilename = ["NDA", "Security Cheque", "Cheque Declaration", "Tenure Commitment Agreement"];
                }else{
                    newFilename = ["NDA","Tenure Commitment Agreement"];
                }
                if (response.data.data.experience === "Intern") {
                    newFilename.push("Internship Letter");
                }else{
                    newFilename.push("Offer Letter");
                }
                setFilename(newFilename);
                setEmpDocs(response.data.data.needToSignDocs)
                // const firstUnsignedOrUnapprovedDoc = filename?.find(name => {
                //     const doc = response.data.data.needToSignDocs?.find(item => item.doc_Type === name);
                //     dispatch(hideAuthLoader());
                //     return doc && (!doc.isSigned);  // Updated this line
                // });
                // if (firstUnsignedOrUnapprovedDoc) {
                //     const doc = response.data.data.needToSignDocs?.find(item => item.doc_Type === firstUnsignedOrUnapprovedDoc);
                //     setCurrentDoc(doc);
                //     setAllDocsSigned(false); // Not all docs are signed or approved
                //     dispatch(hideAuthLoader());
                // } else {
                //     setAllDocsSigned(true); // All docs are signed and approved
                //     dispatch(hideAuthLoader());
                //     console.log('All documents are signed and approved.');
                // }
            }
            if (Array.isArray(response.data.data) && response.data.data.length === 0) {
                setAlreadySignMsg("Your documents uploaded successfully !");
                dispatch(hideAuthLoader());
            }
        } catch (error) {
            dispatch(hideAuthLoader());
            console.log(error);
        }
    };





    // const filename = ["NDA", "Security Cheque", "Cheque Declaration", "Tenure Commitment Agreement", 'Offer Letter','Internship Letter']

    const changeFile = (file) => {
        let doc = empDocs?.find((item) => item.doc_Type == file)
        setCurrentDoc(doc)
    };

    return (
        <div className="onboarding-candidate">
            <div className="elsner_onboarding_header">
                <div className="logo">
                    <a href="/">
                        <img src={ElsnerLogo} alt="Elsner Logo" />
                    </a>
                </div>
            </div>

            {allDocsSigned ? (
                <div style={{ color: 'green', display: 'flex', justifyContent: 'center', fontSize: '20px', margin: '20px' }}>{alreadySignMsg}</div>
            ) : (
                <>
                    <Card title="Documents To Be Signed By Candidate">
                        <div className='signed_button'>
                            {filename.map((file, index) => {
                                const doc = empDocs?.find(doc => doc.doc_Type === file);
                                const isApproved = doc ? doc.status === 'Approve' : false;  // Check if status is 'Approve'
                                const isSigned = doc ? doc.isSigned : false;
                                // Styles for active and inactive buttons
                                const activeStyle = {
                                    backgroundColor: '#187cb7',
                                    color: 'white',
                                };

                                const inactiveStyle = {
                                    backgroundColor: 'transparent',
                                    color: 'grey',
                                };
                                const buttonStyle = file === currentDoc.doc_Type ? activeStyle : inactiveStyle;

                                return (
                                    <Button style={buttonStyle} disabled={isApproved || isSigned} key={index} onClick={() => changeFile(file)}>
                                        {file}{isApproved || isSigned ? <CheckCircleOutlined style={{ color: 'green' }} /> : null}
                                    </Button>
                                )
                            })}
                        </div>
                    </Card>
                    <SignDocs initialFile={currentDoc} getEmpDataFunction={getOnboardingEmpDocs} />
                </>
            )}


            <div className="elsner_onboarding_footer">
                <p>{footerText}</p>
            </div>
        </div>
    );
};

export default SignedDocCandidate;
