import axios from "axios";
import { message } from "antd";
const { REACT_APP_API_URL } = process.env;
export default class Service {
  static Server_Base_URL = REACT_APP_API_URL;
  static API_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL + "/api"
      : `${this.Server_Base_URL}/api`;

  static API_Call_Counter = 0;
  static incre_API_Call_Counter = () => this.API_Call_Counter++;
  static decre_API_Call_Counter = () =>
  (this.API_Call_Counter =
    this.API_Call_Counter > 0 ? this.API_Call_Counter - 1 : 0);

  static error_message = "Something went wrong!";
  static error_message_key = "error_message_key";

  static message_containner = [];
  static add_message = (text) => {
    var index = this.message_containner.findIndex((x) => x === text);
    // here you can check specific property for an object whether it exist in your array or not
    if (index === -1) {
      this.message_containner.push(text);
    }
    return index;
  };
  static remove_message = (message) =>
  (this.message_containner = this.message_containner.filter(
    (m) => m !== message
  ));
  static messageError = (msg) => {
    const index = this.add_message(msg);
    if (index === -1) {
      message.error(msg).then(() => {
        this.remove_message(msg);
      });
    }
  };

  static messageInfo = (msg) => {
    const index = this.add_message(msg);
    if (index === -1) {
      message.info(msg).then(() => {
        this.remove_message(msg);
      });
    }
  };

  static postMethod = "POST";
  static getMethod = "GET";
  static putMethod = "PUT";
  static deleteMethod = "DELETE";
  static headers = {
    accept: "application/json",
    "content-type": "application/json",
  };

  //Organisation-tree
  static organisationtree = "/emp/employeeTreeList";
  static mergechildren = "/emp/mergeEmployeeData";

  //Auth Module
  static login = "/admin/login";
  static userById = "/admin/userById";
  static empById = "/emp/getempbyid";
  static editAdmin = "/admin/editadminuserprofile";
  static refreshToken = "/auth/refreshToken";
  static forgotPassword = "/auth/forgotPassword";
  static resetPassword = "/auth/resetPassword";

  //appraisals
  static addAppraisalMeetings = "/appraisal/addeditMeetings";

  //feedback
  static feedbackApi = "/feedback/emp_feedback";
  static postFeedbackData = "/feedback/saveempdataforfeedback";

  //icon & logo
  static editLogo_Icon = "/adminsettings/editAdminSetting";
  static customadminSetting = "/adminsettings/customadminSetting";
  static getAdminSettings = "/adminsettings/adminSetting";

  //previous Event
  static previousEvents = "/admin/event/previousEvents";
  static liveEvents = "/admin/event/liveEvents";

  static upcomingEvents = "/admin/event/upcomingEvents";

  //empleave
  static getempleave = "/empInOutTime/getLeaveOnCalender";
  //trainner module
  static trainnerList = "/host/trainnerList";
  //Elevate-forms -satisfaction
  static employeessurvey = "/admin/dashboard/employeessurvey";
  static employeesStatisfaction = "/admin/employeesStatisfaction";

  //department module
  static departmentList = "/department/departmentList";
  static departmentDropdownList = "/department/departmentDropdownList";
  static designationDropdownList = "/event/designationDropdownList";
  static empdesignationDropdownList =
    "/empdepartment/empdesignationDropdownList";
  static departmentAdd = "/department/addDepartment";
  static departmentById = "/department/departmentById";
  static editDepartment = "/department/editDepartment";
  static deleteDepartment = "/department/deleteDepartment";

  static empDepartmentList = "/empdepartment/empdepartmentList";
  static empDepartmentAdd = "/empdepartment/empaddDepartment";
  static empDeleteDepartment = "/empdepartment/empdeletDepartment";
  static empEditDepartment = "/empdepartment/empeditDepartment";

  static AddDEptHead = '/audits/add/department/head';
  static getDepHead = '/audits/get/list/department/head';
  static deleteDepHead = '/audits/delete/department/head';
  static editDepHead = '/audits/update/department/head';

  static empSubDepartmentDropdownList = "/subDepartment/subDepartmentDropdownList";



  static empDesignationList = "/empdesignation/empdesignationList";
  static empDesignationAdd = "/empdesignation/empaddDesignation";
  static empDeleteDesignation = "/empdesignation/empdeleteDesignation";
  static empEditDesignation = "/empdesignation/empeditDesignation";

  //add admin
  static addAdminUser = "/admin/addAdminUser";
  static rolesDropdownList = "/roles/rolesDropdownList";
  static getAdminList = "/admin/adminuserlist";
  static adminSetting = "/adminsettings/adminSetting";

  //WFH module

  static addwfh = "/wfh_request/addWFH";
  static wfhlisting = "/wfh_request/WFHList";
  static wfhapprove = "/wfh_request/ApproveWFHApplication";
  static wfhreject = "/wfh_request/RejectWFHApplication";
  static adminwfhapprove = "/wfh_request/ApproveWFHApplicationadmin";
  static adminwfhreject = "/wfh_request/RejectWFHApplicationadmin";

  //employeee
  static employees = "/employees/employeesList";
  static importUsers = "/importFile/importUsers";
  static addEmployees = "/employees/addEmployees";
  static employeebyId = "/employees/employeeById";
  static editEmployee = "/employees/editEmployee";
  static employeesDropdownList = "/employees/employeesDropdownList";
  static exportUsers = "/employees/exportUsers";
  static employeeAccountAction = "/employees/employeeAccountAction";
  static employeeView = "/admin/event/myScoreAttendedEvent";
  static birthdatelist = "/employees/birthdatelistAdmin";
  static addPostcardTemplete = "/postCardTemplete/addPostcardTemplete";
  static postCardTempleteList = "/postCardTemplete/postCardTempleteList";
  static deletePostcardTempleteById =
    "/postCardTemplete/deletePostcardTempleteById";
  static editPostcardTemplete = "/postCardTemplete/editPostcardTemplete";
  static addpostcard = "/postcard/addpostcard";
  static postcardlist = "/postcard/postcardlist";
  static previewtemplete = "/postcard/previewtemplete";
  static deletePostcardById = "/postcard/deletePostcardById";

  //event
  static totalAttendUser = "/event/totalAttendUser";

  //eventAttendanceList
  static eventAttendanceList = "/man-attendence/getEvents";
  static userAttendanceList = "/man-attendence/getEventUser";
  static registerAttendance = "/man-attendence/registerAttendance";
  static getNotAttedanceUser = "/man-attendence/getNotAttedanceUser";

  //exportEvents
  static exportEvents = "/admin/event/exportEvents";
  //notification
  static notificationList = "/admin/notificationList";
  static eventDropdownList = "/event/eventDropdownList";
  static customNotification = "/notification/customNotification";
  static deleteNotification = "/notification/deleteNotification";

  //participateUser
  static participateUser = "/event/totalUser";
  static ExportparticipateUser = "/event/exporttotalUser";

  //eventType module
  static eventsType = "/eventType/eventTypeList";
  static eventTypeAdd = "/eventType/addEventTypes";
  static eventTypeById = "/eventType/eventTypeById";
  static eventsTypeEdit = "/eventType/editEventType";
  static eventTypeDelete = "/eventType/deleteEventType";

  // employee master
  static addemp = "/emp/addemp";
  static empmasterdepartment = "/empdepartment/empdepartmentDropdownList";
  static empmasterdesignation = "/empdepartment/empdesignationDropdownList";
  static getempmaster = "/emp/getempbyid";
  static editempmaster = "/emp/editemp";
  static emplist = "/emp/employeesList";
  static internlist = "/emp/internsList";
  static empdelete = "/emp/deleteEmp";
  static sendwelcomemail = "/emp/welcomeemail";
  static sendwelcomenoteemail = "/emp/welcomenoteemail";
  static deleteEmpimgandsign = "/emp/deleteEmpimgandsign";
  static sendfeedbackemail = "/emp/sendfeedbackemailcron";

  static empAddress = "/emp/address";
  static empPersonalInfo = "/emp/personal_info";
  static empBankDetails = "/emp/bank_details";
  static additionalInfoUrl = "/emp/addition_details";
  static contactList = "/emp/get/contacts";
  static addContact = "/emp/contacts";
  static deleteContact = "/emp/contacts/delete";

  //Admin In-out
  static empinout = "/empInOutTime/getEmpInoutTimeAdmin";
  static deletebulkinout = "/empInOutTime/deleteEmpInOut";
  static deletinout = "/empInOutTime/deleteEmpInOutTime";
  static viewmuliplepunch = "/empInOutTime/viewmultiplepunch";
  static empdropdownlistbyreportingmanager = "/emp/empdropdownlistbyreportingmanager";
  static empdropdownlistbysubreportingmanager = "/emp/empdropdownlistbysubreportingmanager";
  static editinout = "/empInOutTime/editEmpInOut";
  static exportinoutcsv = "/empInOutTime/exportCsvEmpInoutTimeAdmin";
  static manualinout = "/empInOutTime/manually/inout";

  static addTeamRanking = "/UserRanking/calculate-rating";

  static empListRankingbySubReportingManager = "/emp/empdropdownlistbysubreportingmanagerRanking";
  static empListRankingbyReportingManager = "/emp/empdropdownlistbyreportingmanagerRanking";
  static empListRanking = "/emp/empdropdownlistRanking";

  // Admin Leave Module

  static leavemaster = "/leave_master/LeaveList/";
  static addleavemaster = "/leave_master/addLeave/";
  static adminleavelist = "/leave_master/EmployessLeaveList";
  static leaveapproval = "/leave_master/ApproveLeaveApplication";
  static leavereject = "/leave_master/RejectLeaveApplication";
  static adminleavebalance = "/leave_master/EmpLeaveList";
  static adminaddleave = "/leave_master/addLeaveApplication";
  static getreportingmanager = "/emp/getreportingmanagerbyempid";
  static adminleavecancellation = "/leave_master/EmployessLeaveCancelationList";
  static admincancellationapprove =
    "/leave_master/ApproveCancelLeaveApplication";
  static admincancellationreject = "/leave_master/RejectCancelLeaveApplication";
  static compoffleaveapprove = "/leave_master/ApprovecompoffApplication";
  static compoffleavereject = "/leave_master/RejectcompoffApplication";
  static editleavebalance = "/leave_master/EditEmpLeave";
  static ApproveCancelLeaveApplicationadmin =
    "/leave_master/ApproveCancelLeaveApplicationadmin";
  static RejectCancelLeaveApplicationadmin =
    "/leave_master/RejectCancelLeaveApplicationadmin";
  static ApproveLeaveApplicationadmin =
    "/leave_master/ApproveLeaveApplicationadmin";
  static RejectLeaveApplicationadmin =
    "/leave_master/RejectLeaveApplicationadmin";
  static TLCompoffApplicationList = "/leave_master/TLCompoffApplicationList";
  static DirectorCompoffApplicationList =
    "/leave_master/DirectorCompoffApplicationList";
  static CEOCompoffApplicationList = "/leave_master/CEOCompoffApplicationList";

  static TLCompoffapproval = "/leave_master/TLApprovecompoffApplication";
  static TLCompoffreject = "/leave_master/TLRejectcompoffApplication";
  static CeoCompoffapproval = "/leave_master/CEOApprovecompoffApplication";
  static CeoCompoffreject = "/leave_master/CEORejectcompoffApplication";
  static DirectorCompoffapproval =
    "/leave_master/DirectorApprovecompoffApplication";
  static DirectorCompoffreject =
    "/leave_master/DirectorRejectcompoffApplication";
  static TLExportCompoffApplicationList =
    "/leave_master/TLExportCompoffApplicationList";
  static CEOExportCompoffApplicationList =
    "/leave_master/CEOExportCompoffApplicationList";
  static DirectorExportCompoffApplicationList =
    "/leave_master/DirectorExportCompoffApplicationList";
  static DirectorExportCompoffApplicationList =
    "/leave_master/DirectorExportCompoffApplicationList";
  static ExportCompoffApplicationList =
    "/leave_master/ExportCompoffApplicationList";
  static exportEmpLeaveList = "/leave_master/exportEmpLeaveList";
  static importEmpLeaveList = "/importFile/emp/leaves";

  // Admin-Department
  // static deleteDepartment = "/department//deleteDepartment";

  //Admin-emp-Regularization - closed

  static adminempreg = "/employeeReg/getRegularisationRequest";
  static directorreg = "/employeeReg/getRegularisationRequestForDirector";
  static ceoreg = "/employeeReg/getRegularisationRequestForCEO";
  static adminApprovereg = "/employeeReg/acceptregularisationadmin";
  static approvereg = "/employeeReg/acceptregularisation";
  static rejectreg = "/employeeReg/rejectregularisation";
  static adminRejectreg = "/employeeReg/rejectregularisationadmin";
  static directorapprove = "/employeeReg/acceptregularisationDirector";
  static directorreject = "/employeeReg/rejectregularisationDirector";
  static ceoapprove = "/employeeReg/acceptregularisationCEO";
  static ceoreject = "/employeeReg/rejectregularisationCEO";

  // Admin Reports

  static attendenceregister = "/employeeReg/attendanceRegisterList";
  static attendenceregistercsv = "/employeeReg/exportAttendanceRegisterListCSV";
  static newattendenceregistercsv =
    "/employeeReg/newexportAttendanceRegisterListCSV";
  static getRegulatisationList = "/employeeReg/getRegulatisationList";
  static getRegulatisationReport = "/employeeReg/getRegulatisationReport";
  static getRegulatisationReportPDF = "/employeeReg/getRegulatisationReportPDF";
  static exportMonthlyRegisterListCSV =
    "/employeeReg/exportMonthlyRegisterListCSV";

  static exportattendenceregister = "/employeeReg/exportAttendanceRegisterList";
  static exportMonthlyRegisterList = "/employeeReg/exportMonthlyRegisterList";
  static leaveapplicationreport = "/employeeReg/leaveList";
  static exportleaveapplicationreport = "/employeeReg/exportLeaveList";
  static exportemployeeinout = "/employeeReg/exportEmployeeInOut";
  static exportemployeeinoutCSV = "/employeeReg/exportEmployeeInOutCSV";

  static exportleaveapproval = "/employeeReg/exportLeaveApproval";
  static employeeleavebalance = "/employeeReg/LeaveBalanceList";
  static exportemployeeleavebalance = "/employeeReg/exportLeaveBalance";
  static joinemployeelist = "/emp/JoinemployeesList";
  static exportjoinemployeelist = "/emp/exportJoinemployeesList";
  static leftemployeereport = "/emp/LeftempList";
  static exportleftemployeereport = "/emp/exportLeftempList";

  // HRMS passwords

  static hrmsforgotpaassword = "/emp/forgotPassword";
  static hrmsresetpassword = "/emp/resetPassword";
  static hrmschangepassword = "/emp/updatePassword";

  // static addemp = "/emp/addemp";
  static empdepartmentDropdownList = "/empdepartment/empdepartmentDropdownList";
  static departmentDropdownList = "/department/departmentDropdownList";
  static exportEmployeeList = "/emp/exportemployeesList";
  static exportDetailedEmployeeList = "/emp/export/details";
  static exemplist = "/emp/LeftemployeesList";
  static editexemp = "/emp/editXemp/";
  static deleteexempdoc = "/emp/DeleteFile";

  static exportExEmployeeList = "/emp/exporLefttemployeesList";
  static add_Resign = "/emp/addresign";
  static empDropdownList = "/emp/empdropdownlist";
  static empDropdownListForLeaveBal = "/emp/empdropdownlistforleaveBal"
  static adminInOutempdropdownList = "/emp/adminInoutDropdown";

  //employee ranking system

  static calculaterank = "/UserRanking/calculate-rating";
  static addemprank = "/UserRanking/addUserRating";
  static empranklist = "/UserRanking/UserRatingList";
  static editemprank = "/UserRanking/editUserRating/";
  static deletemprank = "/UserRanking/deleteUserRating";
  static exportemprank = "/employeeReg/exportUserRanking";
  static deletmultiemprank = "/UserRanking/deleteMultipleUserRating";
  static editemprankmaster =
    "/performance-attribute-category/editPerformanceCategory/";
  static emprankchart = "/UserRanking/userRatingchart";
  static exportuserRatingListing = "/UserRanking/exportuserRatingListing";

  static departmentByReportingManager = "/emp/departmentByReportingManager";

  //event list
  static eventsList = "/admin/event/eventList";
  static getAdminEventViewById = "/admin/event/getAdminEventViewById";
  static eventAdd = "/event/addEvent";
  static eventById = "/event/eventById";
  static editEvent = "/event/editEvent";
  static saveEventNotification = "/event/saveEventNotification";
  static eventDelete = "/event/deleteEvent";
  static eventTypeDropdownList = "/eventType/eventTypeDropdownList";
  static eventClone = "/event/eventClone";
  static eventStatus = "/event/eventStatus";
  static getEventForm = "/elevateForms/getEventForm";
  static feedbackById = "/feedback/feedbackById";
  static saveEventForm = "/elevateForms/saveEventForm";
  static exportfeedbackList = "/feedback/exportfeedbackList";
  static Averagefeedback = "/feedback/Averagefeedback";

  static addsurvey = "/surveys/addsurvey";
  static editsurvey = "/surveys/editSurvey";
  static surveyList = "/surveys/surveyList";
  static surveyById = "/surveys/surveyById";
  static SurveyUsersList = "/surveys/SurveyUsersList";
  static surveyAnswersById = "/surveys/surveyAnswersById";

  //manual user attendedence
  static eventIsAttended = "/event/eventIsAttended";
  static user_eventIsAccept = "/event/user_eventisAccept";

  //dashboard data
  static event_calander = "/admin/dashboard/event_calender";
  static totalupcomingscount = "/admin/dashboard/upcoming_events_count";
  static totalptivioscount = "/admin/dashboard/previous_events_count";
  static totaleventscount = "/admin/dashboard/total_events_count";
  static employeesrank = "/admin/dashboard/employeesrank";
  static cancel_events_count = "/admin/dashboard/cancel_events_count";
  static live_events_count = "/admin/dashboard/live_events_count";

  //Feedback and Elevate-forms
  static elevateFormList = "/elevateForms/elevateFormList";
  static feedbackList = "/feedback/feedbackList";
  static addElevateForms = "/elevateForms/addElevateForms";
  static editElevateForm = "/elevateForms/editElevateForm";
  static elevateFormById = "/elevateForms/elevateFormById";
  static deleteElevateFormById = "/elevateForms/deleteElevateFormById";

  //  user In-out
  static addintime = "/empInOutTime/addInTime";
  static addoutTime = "/empInOutTime/addOutTime";
  static getemprecord = "/empInOutTime/getEmpInoutTime";
  static empimportcsv = "/empInOutTime/importcsv";
  static exportInOutList = "/empInOutTime/exportAttendanceCsv";

  // user attendence Regularization
  static getemployeeAttendence = "/employeeReg/getEmployeeAttendance";
  static addRegularization = "/employeeReg/reqRegularisation";

  //  user leave module
  static addLeaveApplication = "/leave_master/addLeaveApplication";
  static employeeLeaveList = "/leave_master/EmployessLeaveList";
  static EmpLeaveListleaveBal = "/leave_master/EmpLeaveListleaveBal";
  static empLeaveBalance = "/leave_master/EmpLeaveList";
  static leavecancellationbyId = "/leave_master/getleaveapplicationbyid";
  static reportingmanager = "/emp/getreportingmanagerbyempid";
  static addleavecancellation = "/leave_master/AddCancelLeaveApplication";
  static empCompOffLeaveBalance = "/leave_master/CompoffLeaveList";
  static addCompOffLeave = "/leave_master/AddcompoffApplication";
  static cOffLeaveList = "/leave_master/CompoffApplicationList";
  static encashment = "/leave_master/EncashmentcompoffApplication";

  // user dashboard
  static userdashboard = "/emp/userdashboard";
  static getholidays = "/holidays/holidayList";
  static adminholidays = "/holidays/adminholidaylist";

  static addholidays = "/holidays/addHoliday";
  static deleteholidays = "/holidays/deleteHoliday";
  static editholidays = "/holidays/holidayById";
  static updateholidays = "/holidays/editHoliday";

  //Team leader
  static getteamattandance = "/empInOutTime/getReportingTodayInOut";

  // Admin dashboard
  static admindashboard = "/emp/admindashboard";
  static getempinout = "/empInOutTime/getTodayInOut";
  static multiplepunches = "/empInOutTime/getmultipalpunchByEmpID";

  //Organization policy

  static organizationpolicy = "/organization-policies/PDFList";
  static deletePDF = "/organization-policies/deletePolicy";
  static addPDF = "/organization-policies/addPDF";
  static editPDF = "/organization-policies/editPDF";
  static DeletePolicyFile = "/organization-policies/DeletePolicyFile";

  //trainer module
  static hostlist = "/host/hostlist";
  static addTrainner = "/host/addhost";
  static hostDropdownList = "/host/hostDropdownList";
  static hostMyEvents = "/host/hostMyEvents";
  static hostAttendanceReport = "/host/hostAttendanceReport";

  //Upload Employee Documents
  static uploadEmployeeDoc = "/employeedoc/uploadDoc";
  static uploadEmployeeDocSign = "/employeedoc/uploadEmpSign";
  static getDocByEmpId = "/employeedoc/getDocbyEmpId";
  static deleteDocbyEmpId = "/employeedoc/deleteDocbyEmpId";
  static downloadDoc = "/employeedoc/download";
  static downloadDocument = "/employeedoc/downloadDocument";

  // separation module 
  static addresign = '/separationNew/separation'
  static getresign = '/separationNew/getseparation'
  static editresign = '/separationNew/editseparation'
  static last_working = '/separationNew/getseparationLWDlist'
  static userResignList = '/separationNew/getmySeparationData'
  static separationByAdminForUser="/separationNew/separationbyAdmin"

  // send exit mails
  static sendExitMails = '/emp/sendfeedbackemailcron'
  static sendPasswordChangeMails = '/emp/changePasswordMailSend'


  //ticket module
  static ticketList = "/ticket/ticketList";
  static addTicket = "/ticket/addTicket";
  static editTicket = "/ticket/editTicket";
  static deleteTicket = "/ticket/deleteTicket";
  static closeTicket = "/ticket/closeTicket";
  static NoteList = "/notes/NoteList";
  static addNote = "/notes/addNote";
  static deleteNote = "/notes/DeleteNote";
  static deleteFile = "/notes/DeleteFile";
  static EditNote = "/notes/EditNote";
  static reopenTicket = "/ticket/reopenTicket";
  static addCommentReferCandidate = "/notes/addCommentReferCandidate";
  static editCommentReferCandidate = "/notes/editCommentReferCandidate";

  // new-attendence regularization
  static addnewRegularization = "/regularization/add";
  static addnewListRegularization = "/regularization/listing";

  // admin side attendence regularization (approve or deny)
  static getregularization = "/regularization/listing";
  static approveonduty = "/regularization/approve";
  static rejectonduty = "/regularization/reject";

  //Probation module
  static ApproveReject = "/emp/update/status";
  static getProbationData = "/emp/get/probation/extend";
  //notification
  static getNotification = "/leave_master/getNotification";
  static readNotification = "/leave_master/readnotification";
  static readNotificationFromUser = "/leave_master/readAllNotification";

  //change leave status from notification
  static approveLeave = "/leave_master/ApproveLeaveApplicationById";
  static rejectLeaveFromNotification =
    "/leave_master/RejectLeaveApplicationById";
  static approveRegFromNotification = "/employeeReg/acceptregularisationById";

  // performance
  static performanceReview = '/empPerformanceReview/list'
  //Goal Module
  static goalListing = "/goals/listGoals";
  static createdlistGoals = "/goals/createdlistGoals";
  static teamlistGoals = "/goals/teamlistGoals";
  static employeelistGoals = '/goals/employeelistGoals'


  //optional holidays
  static optionalHolidays = '/optionalLeave/getOptionalLeaveByUserId'
  static applyOptHoliday = '/optionalLeave/applyOptionalLeave '

  // goal list with subgoals --- listingWithSubgoals
  static listingWithSubgoals = "/goals/listingWithSubgoals";
  static goalListExport = "/goals/exportGoalsCSV";
  static exportGoalsforcreatedbyCSV = "/goals/exportGoalsforcreatedbyCSV";
  static addGoal = "/goals/addGoal";
  static editGoal = "/goals/editGoal";
  static goalByID = "/goals/goalById";
  static deleteGoal = "/goals/deleteGoal";
  static addWeeklyStatus = "/goals/addWeeklyStatus";
  static subgoalListing = "/subgoals/listsubGoals";
  static subgoalById = "/subgoals/editsubgoal";
  static deleteActionItem = "/goals/deleteActionItem";
  static deleteMilestoneItem = "/goals/deleteMilestoneItem";
  static listWeeklyStatus = "/goals/listWeeklyStatus";
  static editWeeklyStatus = "/goals/editWeeklyStatus";
  static deleteWeeklyStatus = "/goals/deleteWeeklyStatus";
  static addsubgoal = "/subgoals/addSubGoal";
  static listingsubgoalbygoalId = "/subgoals/listingsubgoalbygoalId";
  static editSubGoal = "/subgoals/editsubgoal";
  static getSubGoal = "/subgoals/subgoalsbyId";
  static deleteSubGoal = "/subgoals/deletegoalsbyId";
  static mySubgoalsList = "/subgoals/mySubgoalsList";

  // goal-reports
  static listGoalReports = "/goals/listGoalReports";
  static listTeamGoalReports = "/goals/listTeamGoalReports";

  // Goal Records

  static listGoalRecords = "/goals/listGoalRecords";
  static editRecord = "/goals/editRecord";

  //KPI
  static adminaddkpi = "/kpi/addKPI";
  static listingkpi = "/kpi/KPIList";
  static adminviewkpi = "/kpi/KPIByID";
  static admineditkpi = "/kpi/editKPI";
  static kpiListforuser = "/kpi/kpiListforuser";
  static viewuserkpi = "/kpi/UserKPIByID";
  static deletekpi = "/kpi/deleteKPI";
  static edituserkpi = "/kpi/updateUserKpi";

  //Inventory
  static addAssestType = "/inventory/addAssestType";
  static AssestTypeDropdownList = "/inventory/AssestTypeDropdownList";
  static AssestTypeList = "/inventory/AssestTypeList";
  static AssestTypeById = "/inventory/AssestTypeById";
  static deleteAssestType = "/inventory/deleteAssestType";
  static editAssestType = "/inventory/editAssestType";

  //Achivements
  static achivementLists = "/achievement/achievementList";
  static achivementDelete = "/achievement/deleteAchivement";
  static addAchivement = "/achievement/addAchievement";
  static achievementById = "/achievement/achievementById";

  static exportLeaveListCSV = "/employeeReg/exportLeaveListCSV";
  static exportLeaveApprovalCSV = "/employeeReg/exportLeaveApprovalCSV";
  static exportLeaveBalanceCSV = "/employeeReg/exportLeaveBalanceCSV";
  static exportLeftEmployeeCSV = "/emp/exportLeftempListCSV";
  static exportJoinemployeesListCSV = "/emp/exportJoinemployeesListCSV";
  static downloadNDA = "/emp/ndaform";

  //ELDI
  static AdminlistingELDI = "/refer-eldi/get";
  static createReferELDI = "/refer-eldi/create";
  static deleteReferELDI = '/refer-eldi/delete';
  static exportDate = '/refer-eldi/export';
  static activity = '/refer-eldi/edit';

  // Assest
  static assetsList = "/inventory/AssestList";
  static assetsTypeDropdownList = "/inventory/AssestTypeDropdownList";
  static addAsset = "/inventory/addAssest";
  static assetById = "/inventory/AssestById";
  static editAsset = "/inventory/editAssest";
  static assetDelete = "/inventory/deleteAssest";
  static assetImportCsv = "/inventory/importAssest";
  static allocateAssest = "/inventory/AllocateAssest";
  static deallocateAssest = "/inventory/DeAllocateAssest";
  static AssestAllocationList = "/inventory/AssestAllocationList";
  static AssestDropdownList = "/inventory/AssestDropdownList";
  static ExportAssestList = "/inventory/ExportAssestList";
  static ExportAssestListForUser = "/inventory/ExportAssestListForUser";
  static ExportAssestTypeList = "/inventory/ExportAssestTypeList";
  static addToScrape = "/inventory/addToScrape";

  //experience details 
  static listingWExp = '/ExpOfEmp/ExpOfEmpList'
  static addExp = '/ExpOfEmp/addExpOfEmp'
  static editExp = '/ExpOfEmp/updateExpOfEmp'
  static deleteExp = '/ExpOfEmp/deleteExpOfEmp'
  static fetchbyid = '/ExpOfEmp/getExpOfEmpById'

  // Admin Job Openings
  static jobOpeningList = "/jobs/JobsList";
  static addJob = "/jobs/addjob";
  static JobById = "/jobs/JobById";
  static editJob = "/jobs/editjob";
  static deleteJob = "/jobs/deletejob";
  static exportJobsList = "/jobs/ExportJobsList";
  static jobstatuschange = "/jobs/jobstatuschange";
  static jobhiringmethodchange = "/jobs/jobhiringmethodchange";

  //business unit
  static businessUnit = "/businessUnits/BusinessUnitList";

  // function/role
  static functionRole = "/functionOrRole/functionsList";

  // sub-department
  static subDepartment = "/subDepartment/subDepartmentList";

  //refer candidate
  static createJobReference = "/jobReference/createJobReference";
  static listReference = "/jobReference/listJobreferences";
  static jobOpeningListDropdown = "/jobReference/jobOpeningListDropdown";
  static deleteJobReference = "/jobReference/deleteJobReference";
  static getJobReferenceById = "/jobReference/getJobReferenceById";
  static editJobReference = "/jobReference/editJobReference";
  static exportJobReferenceList = "/jobReference/ExportJobReferenceList";

  //pending task list
  static pendingTaskList = "/leave_master/pendingTaskList";

  // job comments
  static addJobComment = "/jobComments/addComment";
  static viewComment = "/jobComments/listComments";
  static editCommentbyId = "/jobComments/commentById";
  static updateComment = "/jobComments/editComment";
  static deleteComment = "/jobComments/deleteComment";

  // app settings
  static getAppSettings = "/app/settings/get";
  static editAppSettings = "/app/settings/edit";

  //new joineees module
  static AddnewJoinee = "/newjoinerDetails/add";
  static AssetsRequired = "/newjoinerDetails/addAssets";
  static Newjoineelist = "/newjoinerDetails/list";
  static getAssets = "/newjoinerDetails/getAssets";

  //onboarding module
  static OnboardingInitiation = "/onboardingEmp/add";
  static uploadDocs = "/onboardingEmp/uploadDocument";
  static submiteveryDoc = "/onboardingEmp/addDocuments";
  static onboardedEmps = "/onboardingEmp/getAllOnboardingEmps";
  static viewdocsList = "/onboardingEmp/documentByOnboardingEmpId";
  static approveRejectdoc = "/onboardingEmp/getAllEmpsDocs";
  static rejection = "/onboardingEmp/rejectedEmpDocs";
  static approval = "/onboardingEmp/approveEmpDocs";
  static submitTarget = "/onboardingEmp/editDocuments";

  static sendNotificationToTL = "/onboardingEmp/sendNotificationToTL";
  static sendDocsToEmp = "/onboardingEmp/sendDocsToEmp";
  static editlistnewjoinee = "/newjoinerDetails/editNewJoinerData";
  //SignDocs
  static getOnboardingEmpDetails = "/onboardingEmp/getOnboardingEmp";
  static getOnboardingEmpDetailsByToken =
    "/onboardingEmp/getOnboardingEmpByToken";

  static signDocsByEmployee = "/onboardingEmp/signedDoc";
  static getEmployeeDetailsByToken = "/onboardingEmp/getEmployeeDetailsByToken";
  static editSignDocsByAdmin = "/onboardingEmp/signDocsEditByAdmin";
  static reSignDocEmail = "/onboardingEmp/reSignDocuments";
  static onBoardingToEmplyee = "/onboardingEmp/onBoardingToEmp";
  static sendDeadlineMail = "/onboardingEmp/sendDeadlineMail";
  static OnBoardingEditReportingManager = "/onboardingEmp/edit";

  // Audit module
  static empListForAudit = "/audits/department";
  static getSopTypeByDepartment = "/audits/sop_type/get";
  static viewauditsdata = "/audits/emp/listing";
  static reportslist = "/audits/reports";
  static getSopBySopType = "/audits/sop/get";
  static editSopById = "/audits/sop/edit";
  static deleteSopById = "/audits/sop/delete";
  static addSopByType = "/audits/sop/add";
  static performance = "/audits/performance";
  static headcommentsappend = "/audits/add/head/comments";
  static getHeadComment = "/audits/get/head/comments";

  static addEditCustomSopTypes = "/audits/sop_type/add_edit";
  static deleteCustomSopType = "/audits/sop_type/delete";
  static deleteSpecificCaseName = "/audits/delete/specific-case";

  static getAuditListOfUsers = "/audits/emp/listing";
  static getEmpAuditHistoryList = "/audits/history";
  static getEmpAuditHistoryListforTL = "/audits/historyforTL";
  static getAuditByMonthEmp = "/audits/emp/month_wise";
  static auditUploadFileforSop = "/audits/upload/file";
  static editDeleteAuditFile = "/audits/update/sopfile";

  static addEditNewAudit = "/audits/add";
  static recordsaddEditNewAudit = "/audits/records/add_edit";
  static auditsrecordsget = "/audits/records/get";
  static auditsrecordsdelete = "/audits/records/delete"
  static downloadAuditHistoryPdf = "/audits/history/exportpdf";
  static bulkSopInsert = "/audits/sop/bulk_insert";

  static auditReportPDF = "/audits/AuditReport/pdfExport";
  static empEditListForAudit = "/audits/department/Head";


  //Intern API Route:
  static interntoemp = "/intern/internToEmp"
  static internperformance = "/intern/internPerformance"
  static experienceLetter="/emp/experienceLetter"
  static internshipLetter="/emp/internshipLetter"

  // department head
  static depthead = "/audits/department/Head"

  //permission module
  static getResources = "/resources/get"
  static getRoles = "/roles/get"
  static getRolesPermission= "/roles/permissions"
  static updatePermission = "/roles/permissions"

  static getempRoles = "/roles/get/employee-role"
  static updateempRoles= "/roles/update/employee-role"


  static userRatingMail='/UserRanking/userRatingchartMail'
  static async makeAPICall({
    props,
    methodName,
    api_url,
    body,
    params,
    options,
  }) {
    api_url = this.API_URL + api_url;

    //request interceptor to add the auth token header to requests
    axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers = {
            accept: "application/json",
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            authorization: "Bearer " + accessToken,
            platform: "web-admin",
            ...options,
          };
        } else {
          config.headers = {
            accept: "application/json",
            "content-type": "application/json",
            platform: "web-admin",
            ...options,
          };
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    //response interceptor to refresh token on receiving token expired error
    axios.interceptors.response.use(
      (response) => {
        if (response.data.code == 401) {
          localStorage.clear();
          window.location = "/signin";
        } else {
          return response;
        }
      },
      async function (error) {
        const originalRequest = error.config;
        let refreshToken = localStorage.getItem("refreshToken");
        if (
          refreshToken &&
          error?.response?.status === 401 &&
          !originalRequest._retry
        ) {
          if (originalRequest.url.includes("/refreshToken")) {
            return Promise.reject(error);
          }
          originalRequest._retry = true;
          try {
            const url = Service.API_URL + Service.refreshToken;
            const response = await axios.post(url, {
              refreshToken: refreshToken,
            });
            if (response.status === 200 && response.data.authToken) {
              localStorage.setItem(
                "accessToken",
                response.data.authToken.accessToken
              );
              localStorage.setItem(
                "refreshToken",
                response.data.authToken.refreshToken
              );
              console.log("Access token refreshed!");
              const res = await axios(originalRequest);
              return res;
            } else {
              console.log("Refresh Token Error", error);
              return Promise.reject(response);
            }
          } catch (e) {
            return Promise.reject(e);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    if (methodName === this.getMethod) {
      if (params) {
        api_url = api_url + "?" + params;
      }
      try {
        const response = await axios.get(api_url);
        this.permissionChange(response.data);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.postMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.post(api_url, body, options);
        this.permissionChange(response.data);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.putMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.put(api_url, body, options);
        this.permissionChange(response.data);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.deleteMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.delete(api_url, { data: body });
        this.permissionChange(response.data);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
  }

  static logOut(props) {
    props.logOutHandler();
    props.history.push("/login");
  }

  static uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  static permissionChange(data) {
    
    try {
        const storage_permission = JSON.parse(localStorage.getItem("resourceAccess"));
        const storage_role_id = JSON.parse(localStorage.getItem("user_data"))        
        const permissions = data.permissions;
                
        function arraysAreEqual(arr1, arr2) {
            const arr1Map = new Map(arr1.map(item => [item._id, item]));
            const arr2Map = new Map(arr2.map(item => [item._id, item]));        
            for (let [key, value] of arr1Map) {
                const arr2Value = arr2Map.get(key);
                if (!arr2Value || 
                    value.name !== arr2Value.name ||
                    value.read !== arr2Value.read ||
                    value.create !== arr2Value.create ||
                    value.update !== arr2Value.update ||
                    value.delete !== arr2Value.delete) {
                                                                               
                    return false;
                }
            }          
            return true;
        }
        if (!arraysAreEqual(permissions, storage_permission) || (storage_role_id && storage_role_id.role_id._id !== data.role_id)) {   
          localStorage.clear();
          window.location.reload();
          console.log("/signin");       
        }
    } catch (error) {
        console.log(error);
    }
}

}


