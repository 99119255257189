import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Input,
  Table,
  Tag,
  Button,
  message,
  Form,
  Row,
  Select,
  DatePicker,
  Col,
  Modal,
  Card,
  TimePicker,
  InputNumber,
  Checkbox,
  Upload,
  Radio,
  Popover,
} from "antd";
import Service from "../../../service";
import { AliyunOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showAuthLoader, hideAuthLoader } from "../../../appRedux/actions/Auth";
import { getResource } from "../../../util/getResourcePermission";
import config from "../../../permission/config.json"
const Search = Input.Search;
const Wfhlisting = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [IsModalView, setIsModalView] = useState(false);
  const [seachEnabled, setSearchEnabled] = useState(false);
  const [val, setVal] = useState("All");
  let [filterData, setFilterData] = useState([]);
  const searchRef = useRef();
  const [empData, setEmpData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let { authUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    getEmployeesLeaveList();
  }, [filterData, searchText, pagination.current, pagination.pageSize]);

  const getEmployeesLeaveList = useCallback(async () => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        emp_code: authUser?.emp_code,
        pageNum: pagination.current,
        pageLimit: pagination.pageSize,
        // application_to_date: filterData?.application_to_date
        //   ? moment(filterData?.application_to_date).format("YYYY-MM-DD")
        //   : moment().format("YYYY-MM-DD"),
        // to_date: moment(filterData?.leave_from_date).format("YYYY-MM-DD")
        //   ? moment()
        //   : filterData?.leave_to_date,
        status: "All",
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        setSearchEnabled(true);
      }
      if (filterData) {
        if (filterData?.status) {
          reqBody.status = filterData.status;
        }
        if (filterData?.application_from_date) {
          reqBody.application_from_date = moment(
            filterData.application_from_date
          ).format("YYYY-MM-DD");
        }
        // if (filterData?.application_to_date) {
        //   reqBody.application_to_date = moment(
        //     filterData.application_to_date
        //   ).format("YYYY-MM-DD");
        // }
        if (filterData?.from_date) {
          reqBody.from_date = moment(filterData.from_date).format("YYYY-MM-DD");
        }
        if (filterData?.to_date) {
          reqBody.to_date = moment(filterData.to_date).format("YYYY-MM-DD");
        }
      }

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.wfhlisting,
        body: reqBody,
      });

      dispatch(hideAuthLoader());
      if (response?.data?.data?.length > 0) {
        if (filterData?.isActive == true) {
          setPagination({
            ...pagination,
            total: response.data.metaData.totalFilteredCount,
            deactivetotal: response.data.metaData.totaldUsers,
          });
        } else {
          setPagination({
            ...pagination,
            total: response.data.metaData.totalFilteredCount,
            // deactivetotal: response.data.metaData.totaldsUsers,
          });
        }

        const employee = response.data.data;
        employee?.map((val, index) => (val["key"] = val?._id));
        setEmpData([]);
        setEmpData(employee);
      } else {
        setPagination({
          ...pagination,
          total: 0,
        });
        setEmpData([]);
        // message.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  }, [filterData, searchText, pagination.current, pagination.pageSize]);

  const filterEmp = async (values) => {
    if (
      (values.application_from_date &&
        values.application_to_date == undefined) ||
      "" ||
      null
    ) {
      message.error("Please select Application To date");
      return;
    }
    if (
      (values.application_to_date &&
        values.application_from_date == undefined) ||
      "" ||
      null
    ) {
      message.error("Please select Application from date");
      return;
    }

    if ((values.from_date && values.to_date == undefined) || "" || null) {
      message.error("Please select WFH to date");
      return;
    }
    if ((values.to_date && values.from_date == undefined) || "" || null) {
      message.error("Please select WFH from date");
      return;
    }
    setFilterData(values);
    setIsModalView(!IsModalView);
  };

  const getFooterDetails = () => {
    return (
      <label>
        Total Records Count is {pagination.total > 0 ? pagination.total : 0}
      </label>
    );
  };

  const handleTableChange = (page, filters, sorter) => {
    setPagination({ ...pagination, ...page });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const openEventModalView = () => {
    // setEvent_record(record);
    setIsModalView(true);
  };
  const closeEventModalView = () => {
    // setEvent_record(record);
    form.resetFields();
    setIsModalView(!IsModalView);
    // setFilterData([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const onSearch = (value) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };

  const resetSearchFilter = (e) => {
    const keyCode = e && e.keyCode ? e.keyCode : e;
    switch (keyCode) {
      case 8:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      case 46:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      default:
        break;
    }
  };
  
  const columns = [
    {
      title: "Application Date",
      dataIndex: "application_date",
      key: "application_date",
      render: (text, record, index) => {
        const event_name = record.application_date
          ? moment(record.application_date).format("DD-MMM-YYYY")
          : "-";
        return (
          // <Link type="button" to={`/event/event-view/${record._id}`}>

          <span style={{ textTransform: "capitalize" }}>{event_name}</span>
          // </Link>
        );
      },
    },
    {
      title: val != "Rejected" ? "Approval Date" : "Rejected Date",
      dataIndex: "leave_approval_date",
      key: "leave_approval_date",
      render: (text, record, index) => {
        const adate = record?.WFH_approval_date
          ? moment(record?.WFH_approval_date).format("DD-MMM-YYYY")
          : "-";
        const adate2 = record?.WFH_rejected_date
          ? moment(record?.WFH_rejected_date).format("DD-MMM-YYYY")
          : "-";
        return <span>{val != "Rejected" ? adate : adate2}</span>;
      },
    },

    {
      title: "Manager",
      dataIndex: "reporting_manager",
      key: "reporting_manager",
      render: (text, record, index) => {
        const adate = record?.reporting_manager;

        return <span>{adate}</span>;
      },
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
      render: (text, record, index) => {
        let adate = moment(record?.from_date).format("DD-MMM-YYYY");
        return <span>{adate}</span>;
      },
    },
    {
      title: "No of Days",
      dataIndex: "no_of_days",
      key: "no_of_days",
      render: (text, record, index) => {
        let adate = record?.no_of_days;

        return <span>{adate}</span>;
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text, record, index) => {
        let adate = record?.reason;
        return (
          <>
            <span>{adate?.substring(0, 50)}</span>
            <br />
            <span>{adate?.substring(50)}</span>
          </>
        );
      },
    },
    {
      title: "Approved",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        if (record.status == "Accepted") {
          const approved_by = record?.is_auto_approved
            ? "Automatically"
            : "By Manager";
          return approved_by ? approved_by : null;
        } else {
          return " - ";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "leave_status",
      render: (text, record, index) => {
        const position = record?.status;

        const color =
          record?.status == "Pending"
            ? "processing"
            : record?.status == "Accepted"
              ? "success"
              : "error";

        return position ? <Tag color={color}>{position}</Tag> : null;
      },
    },
  ];

  return (
    <>
     <Link to="/leave-application">
     <Button type="primary" style={{ marginLeft: "5px" }}>
        Back
      </Button></Link>
      <Card className="employee-card" title="Work From Home Applications">
      {
        getResource(config.RESOURCES.WHF_APPLICATIONS,["create"]) && (
        <Link to="/add-work-from-home-application">
          {" "}
          <Button
            type="primary"
            className="addleave-btn"
            style={{ float: "right" }}
          >
            Add WFH Application
          </Button>
        </Link>
        )
      }
        <Search
          ref={searchRef}
          placeholder="Search..."
          onSearch={onSearch}
          onKeyUp={resetSearchFilter}
          style={{ width: 200 }}
          className="mr2"
        />
        <Button
          onClick={() => openEventModalView()}
          className="mr2"
          type="primary"
        >
          Filter
        </Button>
        <Button
          onClick={() => {
            form.resetFields();
            setFilterData([]);
            setVal("All");
          }}
          className="mr2"
          disabled={filterData.length != 0 ? false : true}
        >
          Clear Filter
        </Button>
        <Modal
          title="Filter"
          width={1100}
          visible={IsModalView}
          okText="Update"
          footer={false}
          onCancel={closeEventModalView}
        >
          <Form form={form} {...formItemLayout} onFinish={filterEmp}>
            <div className="add-registration leave_form">
              {" "}
              <Row>
                <Col sm={24} md={12}>
                  <Form.Item
                    label="Application From Date "
                    name="application_from_date"
                    className="search-row"
                    onChange={
                      form.getFieldValue("application_to_date") == undefined ||
                        "" ||
                        null
                        ? form.setFieldsValue({
                          application_to_date:
                            filterData?.application_from_date,
                        })
                        : ""
                    }
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      className="fulldate-width"
                    // disabledDate={disabledDate}
                    />
                  </Form.Item>
                  <Form.Item
                    label="WFH From Date "
                    name="from_date"
                    className="search-row"
                    onChange={
                      form.getFieldValue("leave_to_date") == undefined ||
                        "" ||
                        null
                        ? form.setFieldsValue({
                          leave_to_date: filterData?.leave_from_date,
                        })
                        : ""
                    }
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      className="fulldate-width"
                    // disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item
                    label="Application To Date "
                    name="application_to_date"
                    className="search-row"
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      className="fulldate-width"
                      disabledDate={(value) => {
                        return (
                          value < form.getFieldValue("application_from_date")
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="WFH To Date "
                    name="to_date"
                    className="search-row"
                  >
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      className="fulldate-width"
                      disabledDate={(value) => {
                        return value < form.getFieldValue("from_date");
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} md={24}>
                  <Form.Item
                    className="radio_group leave-radio ant-col-xs-24 ant-col-sm-24"
                    name="status"
                    initialValue="All"
                  >
                    <Radio.Group
                      name="radiogroup"
                      className="start-enddate"
                      onChange={(e) => {
                        setVal(e.target.value);
                      }}
                    >
                      <Radio value="All">All</Radio>
                      <Radio value="Pending">Pending</Radio>
                      <Radio value="Accepted">Approved</Radio>
                      <Radio value="Rejected">Rejected</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    {...tailFormItemLayout}
                    className="ant-col-xs-24 ant-col-sm-24 leavebtns"
                  >
                    <div className="add-leave-button">
                      {" "}
                      <Button type="primary" htmlType="submit">
                        Go
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          form.resetFields();
                          setFilterData([]);
                          // setIsModalView(!IsModalView);
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Clear
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal>

        <Table
          columns={columns}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            ...pagination,
          }}
          // rowSelection={rowSelection}
          footer={getFooterDetails}
          onChange={handleTableChange}
          dataSource={empData}
        />
      </Card>
    </>
  );
};

export default Wfhlisting;
